export default {
  HEADING: 'KV-Dienst eintragen',
  LINK_BACK: 'Zurück',
  BTN: {
    SUBMIT: 'KV-Dienst speichern und abschließen',
  },
  STEPS: [
    {
      HEADING: '1. KV-Dienst eintragen',
    },
    {
      HEADING: '2. KV-Dienst überprüfen',
      SUB_HEADING: 'Zusatzoptionen wählen',
      OPTIONS: [
        {
          HEADING: 'Rundum-Sorglos-Paket',
          TEXT: 'Sie möchten Zeit sparen und die Auswahl des Vertreters in unsere Hände geben? Wir übernehmen mit dem Rundum-Sorglos-Paket die gesamte Auftragskommunikation In der Vermittlungsgebühr für das "Rundum-Sorglos-Paket" von 199€ ist zusätzlich auch die Priority-Option enthalten. Ihr KV-Dienst wird unseren Vertretungsärzten empfohlen und in der Liste hervorgehoben. Dadurch erlangt Ihr Dienst höhere Aufmerksamkeit und erhält schneller passende Angebote.',
          INFO: [
            'Persönlicher Berater',
            'Priority',
          ],
          BUTTON: 'Zubuchen',
        },
        {
          HEADING: 'Sofort-Zusage',
          TEXT: [
            'Die Sofort-Zusage ermöglicht dem Vertretungsarzt, sich Ihren Dienst direkt und ohne Wartezeit zu sichern. Er akzeptiert damit automatisch das Mindesthonorar von {minFee}€/Std. für diesen Dienst.',
            'Sobald sich ein Vertreter für Ihren Dienst entscheidet, entstehen verbindliche Kosten für die arztpool-Vermittlungsgebühr.',
          ],
          SAMPLE_CONTRACT: `Mit der Sofort-Zusage gilt der <a href="${
            import.meta.env.ARZTPOOL_META_BASE_URL
          }sampleContract" target="_blank">Dienstvertretungsvertrag</a> auch von Ihrer Seite als verbindlich akzeptiert.`,
        },
        {
          HEADING: 'Priority',
          TEXT: [
            'Einfach Aufmerksamkeit erzielen - Ihr KV-Dienst wird unseren Vertretungsärzten empfohlen und in der Liste hervorgehoben. Dadurch erlangt Ihr Dienst höhere Aufmerksamkeit und erhält schneller passende Angebote.',
            'Zusatzinfo: diese Kosten werden unabhängig der Vermittlung in Rechnung gestellt.',
          ],
        },
      ],
      EXPRESS: {
        HEADING: 'Express-Auftrag',
        TEXT: 'Sie übergeben uns digital die Dienstdaten, wir kümmern uns den Rest: Dabei erhalten Sie alle Vorteile des Eil-Auftrags. Zudem organisieren wir für Sie die Bewerberanfragen im Rahmen Ihrer Honorarvorgaben.',
      },
      INFORMATION: `Es entstehen Ihnen noch keine Kosten, Sie zahlen erst bei erfolgreicher Vermittlung dieses Dienstes.
      <br/>
      Es gelten unsere <a href="${import.meta.env.ARZTPOOL_META_BASE_URL}conditions#customer" target="_blank">aktuellen Allgemeinen Geschäftsbedingungen</a>
      `,
      OVERVIEW: {
        HEADING: 'Wie werden Ihre voraussichtlichen Kosten kalkuliert?',
      },
    },
  ],
};
