export default {
  HEADING: 'Information zu Ihrer Bewerberauswahl',
  TEXT_INFO:
    'Sie haben sich für {applicantName} als Vertretung Ihres KV Dienstes entschieden. Mit der Bestätigung dieser Information nehmen Sie ihn verbindlich an. Ein Wechsel der Vertretung ist danach nicht mehr möglich.',
  TEXT_CANCEL: 'Klicken Sie auf Abbrechen, um zu der Bewerberauswahl zurückzukehren.',
  TEXT_CHECKBOX: `Ich akzeptiere die <a href="${import.meta.env.ARZTPOOL_META_BASE_URL}conditions#customer" target="_blank">AGB</a> und schließe den <a href="${
    import.meta.env.ARZTPOOL_META_BASE_URL
  }sampleContract" target="_blank">Vertretungsvertrag</a> mit dem Vertreter ab`,
  BUTTON_CANCEL: 'Abbrechen',
  BUTTON_SELECT: 'Bewerbung verbindlich annehmen und Dienstvertretungsvertrag abschließen',
};
