export default {
  META: {
    TITLE: 'Allgemeine Geschäftsbedingungen',
  },
  HEADING: 'Allgemeine Geschäftsbedingungen der arztpool 365 GmbH',

  CUSTOMER: {
    HEADING: 'AGB Kunden',
    PREAMBLE: {
      HEADING: 'Präambel',
      TEXT: [
        'Die Kassenärztlichen Vereinigungen stellen die kassenärztliche Versorgung unter anderem durch den Ärztlichen Bereitschaftsdienst sicher. Zur Teilnahme am Ärztlichen Bereitschaftsdienst sind alle Mitglieder, inklusive angestellten Ärzten (Dauerassistenten), Medizinische Versorgungszentren / Einrichtungen (§ 311 SGB V) und Vertragsarztpraxen, der jeweiligen Kassenärztlichen Vereinigung verpflichtet. Den zur Teilnahme am Bereitschaftsdienst Verpflichteten ist es gestattet, eine Vertretung zu benennen, die den Bereitschaftsdienst übernimmt.',
        'Das Geschäftsmodell der arztpool 365 GmbH ist die Vermittlung von Vertretungsärzten (nachfolgend <strong>„Vertreter“</strong>) für den Ärztlichen Bereitschaftsdienst (nachfolgend <strong>„KV-Dienste“</strong>) an Mitglieder der Kassenärztlichen Vereinigungen, die diese KV-Dienste abgeben möchten (nachfolgend <strong>„Kunden“</strong>).',
      ],
    },
    SCOPE: {
      HEADING: '§ 1 Geltungsbereich',
      TEXT: [
        'Diese Allgemeinen Geschäftsbedingungen (nachfolgend <strong>„AGB“</strong>) gelten für Vertragsverhältnisse zwischen der arztpool 365 GmbH, Frankfurter Allee 31a, 10247 Berlin (nachfolgend <strong>„arztpool“</strong>), und dem <strong>Kunden</strong>.',
        'arztpool und der Kunde werden nachfolgend jeweils einzeln <strong>„Partei“</strong> und zusammen die <strong>„Parteien“</strong> genannt.',
        `Die jeweils geltende Fassung der AGB kann jederzeit auf der Website von arztpool unter <a href="${
          import.meta.env.ARZTPOOL_META_BASE_URL
        }conditions" target="_blank">arztpool.de/conditions</a> eingesehen und heruntergeladen werden; die in den AGB vermerkte Datumsangabe kennzeichnet dabei den jeweils aktuellen Stand der AGB.`,
        'Diese AGB gelten zwischen den Parteien, sofern nicht etwas anderes vereinbart wurde.',
      ],
    },
    SUBJECT_AND_CONCLUSION: {
      HEADING: '§ 2 Vertragsgegenstand und Zustandekommen des Vertrages',
      TEXT: [
        'arztpool bietet einen Service für die Vermittlung von KV-Diensten und den damit verbundenen Dienstleistungen von Kunden und Vertretern an. Zu diesem Zweck betreibt arztpool ein Online-Vermittlungsportal (nachfolgend <strong>"Vermittlungsportal"</strong>), auf dem Kunden KV-Dienste zur Vermittlung anbieten und Vertreter sich auf die Vertretung dieser KV-Dienste bewerben können.',
        'Mit der Registrierung auf dem Vermittlungsportal von arztpool akzeptiert der Kunde die jeweils gültige Fassung dieser AGB.',
        'Im Zusammenhang mit der Vertretung und Durchführung der KV-Dienste, bietet arztpool dem Kunden zusätzliche Dienstleistungen (nachfolgend <strong>„Zusatzdienstleistungen“</strong>) an. Der Kunde kann diese Zusatzdienstleistungen für jeden KV-Dienst hinzubuchen.',
        `Vermittelt arztpool einen Vertreter und einen Kunden zur Vertretung eines KV-Dienstes, schließen der Kunden und der Vertreter hieraufhin einen separaten Vertrag (nachfolgend <strong>„Vertretungsvertrag“</strong>), der die Rechte und Pflichten des Kunden und des Vertreters hinsichtlich der Leistungserbringung im Rahmen der Vertretung des KV-Dienstes regelt. arztpool stellt dem Kunden und dem Vertreter eine Mustervorlage eines <a href="${
          import.meta.env.ARZTPOOL_META_BASE_URL
        }sampleContract" target="_blank">Vertretungsvertrages</a> zur Verfügung. Für den Inhalt des Vertretungsvertrages sind ausschließlich der Kunde und der Vertreter verantwortlich. Das Vertragsmuster stellt einen unverbindlichen Vorschlag dar und ersetzt keine rechtliche Beratung im Einzelfall. arztpool übernimmt keine Haftung für Schäden, die durch die Verwendung dieses Vertragsmusters entstehen. arztpool ist klarstellend nicht Partei dieses Vertretungsvertrages.`,
      ],
    },
    RIGHTS_AND_OBLIGATIONS: {
      HEADING: '§ 3 Rechte und Pflichten Kunden',
      TEXT: [
        'Nach erfolgreicher Registrierung und Anmeldung im Vermittlungsportal von arztpool kann der Kunde dort KV-Dienste einstellen, auf die sich Vertreter bewerben oder bei entsprechender Option sofort buchen können (nachfolgend <strong>„Sofortzusage“</strong>). Der Kunde macht hierbei alle erforderlichen Angaben zu dem KV-Dienst, inklusive der Spanne der an den Vertreter zu zahlende Vergütung (nachfolgend <strong>„Vergütung“</strong>).',
        'Der Kunde haftet dem Vertreter für die Vergütung für die Vertretung des KV-Dienstes auf Grundlage des Vertretungsvertrages. Ein Vergütungsanspruch des Vertreters gegenüber arztpool besteht ausdrücklich nicht. Der Kunde stellt arztpool im Falle einer solchen Inanspruchnahme durch einen Vertreter des Kunden auf erstes Anfordern hin frei.',
        'Der Kunde hat keinen Anspruch auf eine erfolgreiche Vergabe von KV-Diensten.',
        'Der Anspruch von arztpool gegen den Kunden auf die im Rahmen des Buchungsvorgangs im Vermittlungsportal ausgewiesenen Vermittlungsgebühr (nachfolgend „Vermittlungsgebühr“), entsteht mit dem Angebot der Vermittlung eines für den entsprechenden KV-Dienst geeigneten Vertreters an den Kunden, bzw. der Buchung durch den Vertreter bei Sofortzusage (zusammen nachfolgend „erfolgreiche Vermittlung“).',
        'Vor einer erfolgreichen Vermittlung kann der Kunde den entsprechenden KV-Dienst kostenfrei stornieren.',
        `Sollte der Kunde nach erfolgreicher Vermittlung, einen KV-Dienst stornieren oder aus anderen Gründen nicht mehr anbieten können, zeigt er dies unverzüglich gegenüber arztpool und dem Vertreter an. Etwaige darüber hinaus bestehende Anzeigepflichten, beispielsweise gegenüber der Abteilung Ärztlicher Bereitschaftsdienst oder der zuständigen KV, bleiben hiervon unabhängig bestehen.
        <br/><br/>
        Sollte der Kunde nach erfolgreicher Vermittlung den KV-Dienst stornieren, der bereits durch die Sofortzusage-Option oder durch die Bestätigung der Übernahme durch den Kunden schon an einen Vertreter zugewiesen wurde, bleibt der Kunde zur Zahlung der entstandenen Vermittlungsgebühr nach § 3.4 an arztpool verpflichtet.`,
        'Soweit im Einzelfall zwischen Vertreter und Kunde nichts anderes vereinbart ist, gilt die Behandlung von Privatpatienten und die Ausstellung von Todesbescheinigungen im Rahmen der KV-Dienste als eigenständige Leistung des Vertreters, die er in eigener Verantwortung direkt mit den Patienten/Kostenträgern abrechnet.',
        'Nach dem für den KV-Dienst vorgesehen Termin, stellt arztpool an den Kunden eine Rechnung, welche innerhalb von 10 Tage zur Zahlung fällig ist.',
      ],
    },
    EXCLUSIVITY: {
      HEADING: '§ 4 Exklusivität',
      TEXT: [
        'Der Kunde verpflichtet sich für einen Zeitraum von zwei Jahren nach Vermittlung einer Vertretungsoption durch die arztpool an einen Vertreter keine Vertretungen durch diesen Vertreter zu beauftragen, die nicht über die arztpool vermittelt wurden.',
        'Während der Geltungsdauer dieser Vertragsbeziehung zwischen den Parteien, wird der Kunde keinem Dritten den selben Auftrag zur Vermittlung eines KV-Dienstes erteilen.',
      ],
    },
    LIMITATION_OF_LIABILITY: {
      HEADING: '§ 5 Haftungsbeschränkung',
      TEXT: [
        `Ansprüche des Kunden auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche des Kunden aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von arztpool, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig sind.
        <br/><br/>
        Bei der Verletzung wesentlicher Vertragspflichten haftet arztpool nur auf den vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich um Schadensersatzansprüche des Kunden aus einer Verletzung des Lebens, des Körpers oder der Gesundheit.
        <br/><br/>
        Die Einschränkungen der Abs. 1 und 2 gelten auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen von arztpool, insoweit Ansprüche direkt gegen diese geltend gemacht werden.`,
        'Vertreter sind im Verhältnis zu arztpools24 weder Erfüllungs- bzw. Verrichtungsgehilfen noch sonstige Personen im Sinne des § 278 Satz 1 BGB. arztpool haftet nicht für Schäden, die ein Vertreter in Ausübung oder anlässlich der Vertretung der KV-Dienste des Kunden verursacht. Gleiches gilt, wenn der Vertreter seine vertraglich geschuldete Leistung nicht, nicht vollständig oder mangelhaft erbringt.',
      ],
    },
    DURATION_AND_TERMINATION: {
      HEADING: '§ 6 Laufzeit und Kündigung',
      TEXT: [
        'Das Vertragsverhältnis auf Grundlage dieser AGB wird auf unbestimmte Zeit geschlossen. Es kann von jeder Partei in Textform mit einer Frist von sechs Monate zum Monatsende gekündigt werden. Mit Wirksamwerden der Kündigung erlischt der Zugang des Kunden zu der Online-Plattform von arztpool.',
        'Das Recht der Parteien zur Kündigung aus wichtigem Grund bleibt unberührt.',
        'Die Pflichten der Parteien gemäß Ziffer 7 (Vertraulichkeit) und 8 (Datenschutz) bestehen auch nach Beendigung des Vertrages fort.',
      ],
    },
    CONFIDENTIALITY: {
      HEADING: '§ 7 Vertraulichkeit',
      TEXT: [
        'Die Parteien verpflichten sich, sämtliche Unterlagen, Daten, Betriebs- und Geschäftsangelegenheiten und sonstigen Informationen, die ihnen von der jeweils anderen Partei übermittelt oder offenbart werden oder von denen die jeweilige Partei in sonstiger Weise Kenntnis erlangt, streng vertraulich zu behandeln; die Parteien verpflichten sich, die Unterlagen, Daten, Betriebs- und Geschäftsangelegenheiten und sonstige Informationen ausschließlich zum Zwecke der Durchführung des jeweiligen Vermittlungsvertrages zu verarbeiten und diese nicht für eigene oder Zwecke Dritter zu nutzen. Kunde und doctari haben – insbesondere unter Berücksichtigung des Stands der Technik – geeignete und angemessene technische und organisatorische Maßnahmen zu ergreifen, um die Einhaltung der Vertraulichkeitsverpflichtung sicherzustellen.',
        'Die Vertraulichkeitsverpflichtung nach Ziffer 7.1 gilt ausnahmsweise nicht, a) wenn und soweit Unterlagen, Daten, Betriebs- und Geschäftsangelegenheiten oder sonstige Informationen bereits offenkundig sind oder nach Abschluss des jeweiligen Vermittlungsvertrages offenkundig werden, ohne dass die jeweilige Partei gegen die Vertraulichkeitsverpflichtung verstoßen hat; b) wenn und soweit die jeweils berechtigte Partei die jeweils andere Partei durch vorherige schriftliche (§ 126 BGB) Zustimmung von der Vertraulichkeitsverpflichtung entbunden hat sowie c) wenn und soweit eine Partei aufgrund einer zwingenden gesetzlichen Bestimmung, einer gerichtlichen Entscheidung oder einer behördlichen Anordnung zur Offenlegung von Unterlagen, Daten, Geschäfts- und  Betriebsangelegenheiten oder sonstigen Informationen verpflichtet ist. In diesem Fall hat diejenige Partei, die zur Offenlegung verpflichtet ist, die jeweils andere Partei darüber unverzüglich in Textform zu informieren, sofern dies nicht durch eine zwingende gesetzliche Bestimmung, eine gerichtliche Entscheidung oder behördliche Anordnung untersagt ist.',
      ],
    },
    DATA_PROTECTION: {
      HEADING: '§ 8 Datenschutz',
      TEXT: [
        'Zum Zwecke der Vertragsdurchführung verarbeitet arztpool personenbezogene Daten des Kunden und übermittelt zu diesem Zweck insbesondere personenbezogene Daten an den Vertreter. arztpool verpflichtet sich, die jeweils geltenden datenschutzrechtlichen Bestimmungen zu beachten.',
        `Die jeweils gültige Datenschutzbestimmungen sind unter <a href="${
          import.meta.env.ARZTPOOL_META_BASE_URL
        }privacy" target="_blank">arztpool24.de/privacy</a> einsehbar.`,
      ],
    },
    CHANGES_TO_TERMS_AND_CONDITIONS: {
      HEADING: '§ 9 Änderungen an den AGB',
      TEXT: [
        'Änderungen dieser AGB werden dem Kunden spätestens 30 Tage vor dem Zeitpunkt ihres Wirksamwerdens in Textform angeboten. Die Änderungen können auch im Vermittlungsportal von arztpool angeboten werden. Der Kunde kann den Änderungen vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens wahlweise zustimmen oder sie ablehnen.',
        'Die Zustimmung des Kunden gilt als erteilt, wenn er seine Ablehnung nicht mindestens 14 Tage vor dem Zeitpunkt des Wirksamwerdens der Änderungen angezeigt hat. Auf diese Genehmigungswirkung wird arztpool bei der Übermittlung des Angebots besonders hinweisen.',
      ],
    },
    FINAL_CLAUSES: {
      HEADING: '§ 10 Schlussbestimmungen',
      TEXT: [
        'Änderungen und Ergänzungen dieser AGB, die Erklärung einer Kündigung sowie die Änderung dieser Klausel bedürfen zu ihrer Wirksamkeit der Schriftform. Die Ersetzung der Schriftform durch die elektronische Form oder die Textform ist ausgeschlossen.',
        'Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam oder undurchführbar sein oder werden, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.',
        'Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.',
        'Gerichtsstand ist Berlin.',
      ],
    },
  },

  SUBSTITUTE: {
    HEADING: 'AGB Vertreter',
    PREAMBLE: {
      HEADING: 'Präambel',
      TEXT: [
        'Die Kassenärztlichen Vereinigungen stellen die kassenärztliche Versorgung unter anderem durch den Ärztlichen Bereitschaftsdienst sicher. Zur Teilnahme am Ärztlichen Bereitschaftsdienst sind alle Mitglieder, inklusive angestellten Ärzten (Dauerassistenten), Medizinische Versorgungszentren / Einrichtungen (§ 311 SGB V) und Vertragsarztpraxen, der jeweiligen Kassenärztlichen Vereinigung verpflichtet. Den zur Teilnahme am Bereitschaftsdienst Verpflichteten ist es gestattet, eine Vertretung zu benennen, die den Bereitschaftsdienst übernimmt.',
        'Das Geschäftsmodell derarztpool 365 GmbH ist die Vermittlung von Vertretungsärzten für den Ärztlichen Bereitschaftsdienst (nachfolgend <strong>„KV-Dienste“</strong>) an Ärzte und Ärztinnen, die diese KV-Dienste abgeben möchten (nachfolgend <strong>„Kunden“</strong>) sowie die Unterstützung der Vor- und Nachbereitung dieser KV-Dienste.',
      ],
    },
    SCOPE: {
      HEADING: '§ 1 Geltungsbereich',
      TEXT: [
        'Diese Allgemeinen Geschäftsbedingungen (nachfolgend <strong>„AGB“</strong>) gelten für Vertragsverhältnisse zwischen der arztpool 365 GmbH, Frankfurter Allee 31a, 10247 Berlin (nachfolgend <strong>„arztpool“</strong>), und Vertretungs-Ärztinnen/Ärzten, (nachfolgend <strong>„Vertreter“</strong>).',
        'arztpool und der Vertreter werden nachfolgend jeweils einzeln <strong>„Partei“</strong> und zusammen die <strong>„Parteien“</strong> genannt.',
        `Die jeweils geltende Fassung der AGB kann jederzeit auf der Website von arztpool unter <a href="${
          import.meta.env.ARZTPOOL_META_BASE_URL
        }conditions" target="_blank">arztpool.de/conditions</a> eingesehen und heruntergeladen werden; die in den AGB vermerkte Datumsangabe kennzeichnet dabei den jeweils aktuellen Stand der AGB.`,
        'Diese AGB gelten zwischen den Parteien, sofern nicht etwas anderes vereinbart wurde.',
      ],
    },
    SUBJECT_AND_CONCLUSION: {
      HEADING: '§ 2 Vertragsgegenstand und Zustandekommen des Vertrages',
      TEXT: [
        'arztpool bietet einen Service für die Vermittlung von KV-Diensten und den damit verbundenen Dienstleistungen von Kunden und Vertretern an. Zu diesem Zweck betreibt arztpool ein Online-Vermittlungsportal (nachfolgend <strong>"Vermittlungsportal"</strong>), auf dem Kunden KV-Dienste zur Vermittlung anbieten und Vertreter sich auf diese KV-Leistungen bewerben können.',
        'Zwischen dem Vertreter und arztpool wird auf der Grundlage dieser AGB kein Arbeitsverhältnis begründet und es findet im Rahmen der Vermittlung keine Arbeitnehmerüberlassung statt. Der Vertreter ist klarstellend nicht in den Geschäftsbetrieb von arztpool eingegliedert.',
        'Mit der Registrierung auf dem Vermittlungsportal von arztpool akzeptiert der Vertreter die jeweils gültige Fassung dieser AGB.',
        `Vermittelt arztpool einen Vertreter und einen Kunden zur Vertretung eines KV-Dienstes, schließen der Kunden und der Vertreter hieraufhin einen separaten Vertrag (nachfolgend „Vertretungsvertrag“), der die Rechte und Pflichten des Kunden und des Vertreters hinsichtlich der Erbringung der Leistung im Rahmen der Vertretung des KV-Dienstes regelt. arztpool stellt dem Kunden und dem Vertreter eine Mustervorlage eines <a href="${
          import.meta.env.ARZTPOOL_META_BASE_URL
        }sampleContract" target="_blank">Vertretungsvertrages</a> zur Verfügung. Für den Inhalt des Vertretungsvertrages sind ausschließlich der Kunde und der Vertreter verantwortlich. Das Vertragsmuster stellt einen unverbindlichen Vorschlag dar und ersetzt keine rechtliche Beratung im Einzelfall. arztpool übernimmt keine Haftung für Schäden, die durch die Verwendung dieses Vertragsmusters entstehen. arztpool ist klarstellend nicht Partei dieses Vertretungsvertrages.`,
        'Ein Anspruch des Vertreters auf Vergütung für die Vertretung des KV-Dienstes besteht nur gegenüber dem Kunden auf Grundlage des Vertretungsvertrages. Ein Vergütungsanspruch gegenüber arztpool besteht nicht. arztpool haftet nicht für die Bonität seiner Kunden.',
      ],
    },
    RIGHTS_AND_OBLIGATIONS: {
      HEADING: '§ 3 Rechte und Pflichten Vertreter',
      TEXT: [
        {
          HEADING:
            'Nach erfolgreicher Registrierung und Anmeldung im Vermittlungsportal von arztpool kann der Vertreter die von den Kunden eingestellten KV-Dienste einsehen und sich auf diese bewerben oder bei entsprechender Option sofort annehmen (nachfolgend <strong>„Sofortzusage“</strong>). Voraussetzung für eine erfolgreiche Registrierung ist es, dass der Vertreter gegenüber arztpool seine Identität nachweist und die Berufsausübungsvoraussetzungen sowie Fachkundenachweise erbringt.',
          TEXT: [
            'Der Vertreter übermittelt arztpool einen Nachweis seiner Identität, seiner Approbation und soweit erforderlich seines Facharztes. Änderungen dieser Angaben sind arztpool gegenüber unverzüglich und unaufgefordert mitzuteilen. Im Falle des Entzugs, Widerrufs oder des Ruhens der Approbation, dürfen keine KV-Dienste mehr übernommen werden, auch wenn diese bereits bestätigt wurden.',
            'Der Vertreter ist verpflichtet, eine Berufshaftpflichtversicherung nach § 95e SGB V abzuschließen und das Bestehen gegenüber arztpool nachzuweisen. Hierzu übermittelt der Vertreter im Rahmen der Registrierung eine Bescheinigung gemäß § 113 Absatz 2 VVG an arztpool. Diese Bescheinigung ist während der gesamten Vertragslaufzeit aktuell zu halten und arztpool insbesondere bei Ablauf unaufgefordert erneut zu übermitteln. Die Mindestversicherungssummen dürfen die gesetzlichen Mindestwerte nicht unterschreiten, vgl. § 95e Absatz 2 SGB V.',
            'Für Ansprüche, die sich im Zusammenhang mit Ziffern 3.1.1 und 3.1.2 ergeben, stellt der Vertreter arztpool auf erstes Anfordern frei.',
          ],
        },
        `Der Vertreter darf sich nur auf solche KV-Dienste bewerben beziehungsweise diese buchen, für deren Vertretung er die jeweils im Einzelfall bestehenden persönlichen und fachlichen Voraussetzungen erfüllt. Sind besondere Fachkundenachweise (Facharzt) erforderlich, so steht die Buchung nur entsprechend qualifizierten Vertretern offen.
        <br/><br/>
        Der Vertreter stellt insbesondere sicher, dass er die von der Kassenärztlichen Vereinigung aufgestellten Anforderungen für die Vertretung im Notfalldienst erfüllt.`,
        'Der Vertreter ist grundsätzlich frei in der Auswahl der KV-Dienste und es besteht weder eine Verpflichtung noch ein Anspruch auf Vertretung bestimmter KV-Dienste. Bei erfolgreicher Vermittlung ist der Vertreter verpflichtet die entsprechenden KV-Dienste des Kunden zu übernehmen.',
        'Der Anspruch von arztpool gegen den Vertreter auf die im Rahmen des Buchungsvorgangs im Vermittlungsportal ausgewiesenen Vermittlungsgebühr (nachfolgend <stong>„Vermittlungsgebühr“</strong>), entsteht mit der Bestätigung des Angebots der Vermittlung durch den Kunden, bzw. der Buchung durch den Vertreter bei Sofortzusage (zusammen nachfolgend <strong>„erfolgreiche Vermittlung“</strong>).',
        'Sollte der vermittelte KV-Dienst aus nicht in der Person des Vertreters liegenden Gründen nicht durchgeführt werden können, wird keine Vermittlungsgebühr fällig.',
        `Sollte der Vertreter nach erfolgreicher Vermittlung eines KV-Dienstes an einen Kunden nicht in der Lage sein diesen KV-Dienst wahrzunehmen, zeigt er dies unverzüglich gegenüber von arztpool und dem Kunden an. Etwaige darüber hinaus bestehende Anzeigepflichten, beispielsweise gegenüber der Abteilung Ärztlicher Bereitschaftsdienst oder der zuständigen KV, bleiben hiervon unabhängig bestehen.
        <br/><br/>
        arztpool wird sich nach entsprechender Anzeige des Vertreters im wirtschaftlich zumutbaren Rahmen bemühen, den entsprechenden KV-Dienst an einen anderen Vertreter zu vermitteln.
        <br/><br/>
        Scheitert die Vermittlung an einen anderen Vertreter, so zahlt der Vertreter in Abhängigkeit der Anzeige zum geplanten KV-Dienst die folgenden Pauschalen an arztpool:

        <ul class="mb-5 ms-6">
          <li>0-3 Tage vor dem KV-Dienst 250 Euro;</li>
          <li>4-10 Tage vor dem KV-Dienst 175 Euro.</li>
        </ul>
        Erscheint der Vertreter aus durch ihn zu vertretenen Gründen ohne Anzeige nicht zu einem an ihn vermittelten KV-Dienst, werden 350 Euro an arztpool fällig.
        <br/><br/>
        Etwaige zusätzliche Ansprüche aus dem Vertretungsvertrag zwischen Kunden und Vertreter, beispielsweise aufgrund von Ansprüchen der Kassenärztlichen Vereinigungen gegen den Kunden aufgrund der nicht erfolgten Durchführung des KV-Dienstes, bleiben hiervon unberührt.
        <br/><br/>
        Der Anspruch von arztpool gegen den Vertreter auf die Vermittlungsgebühr bleibt klarstellend bestehend.`,
        'Nach Erbringung des KV-Dienstes sendet der Vertreter alle im Zusammenhang mit der Erbringung der KV-Leistung vom Kunden und/oder arztpool erhaltenen Gegenstände an die Stelle zurück, von der er sie erhalten hat. Die Notfallscheine übersendet der Vertreter unverzüglich an den Kunden.',
        `Der Vertreter rechnet die erbrachten Leistungen innerhalb von 10 Tagen nach Durchführung des KV-Dienstes gegenüber dem Kunden ab. Vorrausetzung für diese Abrechnung ist die Übersendung der im Rahmen des KV-Dienstes erstellten Notfallscheine und falls zur Verfügung gestellt, des Kartenlesegeräts des Kunden.
        <br/><br/>
        Soweit im Einzelfall zwischen Vertreter und Kunde nichts anderes vereinbart ist, gilt die Behandlung von Privatpatienten und die Ausstellung von Todesbescheinigungen im Rahmen der KV-Dienste als eigenständige Leistung des Vertreters, die er in eigener Verantwortung direkt mit den Patienten/Kostenträgern abrechnet.`,
        'Der Vertreter hat die übertragenen KV-Dienste persönlich zu erbringen. Die an den Vertreter übertragenen KV-Dienste dürfen von diesem nicht ohne schriftliche Zustimmung des Kunden und von arztpool weiter übertragen werden.',
        'Nach dem für den KV-Dienst vorgesehen Termin, stellt arztpool an den Vertreter eine Rechnung, welche innerhalb von 10 Tage zur Zahlung fällig ist.',
      ],
    },
    EXCLUSIVITY: {
      HEADING: '§ 4 Exklusivität',
      TEXT: 'Der Vertreter verpflichtet sich für einen Zeitraum von zwei Jahren nach Vermittlung einer Vertretungsoption durch die arztpool an einen Kunden keine Vertretungen für diesen Kunden zu übernehmen, die nicht über die arztpool vermittelt wurden.',
    },
    LIMITATION_OF_LIABILITY: {
      HEADING: '§ 5 Haftungsbeschränkung',
      TEXT: [
        `Ansprüche des Vertreters auf Schadensersatz sind ausgeschlossen. Hiervon ausgenommen sind Schadensersatzansprüche des Vertreters aus der Verletzung des Lebens, des Körpers, der Gesundheit oder aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von arztpool, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig sind.
        <br/><br/>
        Bei der Verletzung wesentlicher Vertragspflichten haftet arztpool nur auf den vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn, es handelt sich um Schadensersatzansprüche des Vertreters aus einer Verletzung des Lebens, des Körpers oder der Gesundheit.
        <br/><br/>
        Die Einschränkungen der Abs. 1 und 2 gelten auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen von arztpool, insoweit Ansprüche direkt gegen diese geltend gemacht werden.`,
        'Vertreter sind im Verhältnis zu arztpool weder Erfüllungs- bzw. Verrichtungsgehilfen noch sonstige Personen im Sinne des § 278 Satz 1 BGB. arztpool haftet nicht für Schäden, die ein Vertreter in Ausübung oder anlässlich der Vertretung der KV-Dienste des Kunden verursacht. Gleiches gilt, wenn der Vertreter seine vertraglich geschuldete Leistung nicht, nicht vollständig oder mangelhaft erbringt. Sollten Dritte im Zusammenhang mit der Tätigkeit des Vertreters anlässlich der Vertretung der KV-Dienste des Kunden Ansprüche gegen arztpool geltend machen, ist der Vertreter verpflichtet, arztpool auf erstes Anfordern von diesen Ansprüchen freizustellen.',
      ],
    },
    DURATION_AND_TERMINATION: {
      HEADING: '§ 6 Laufzeit und Kündigung',
      TEXT: [
        'Das Vertragsverhältnis auf Grundlage dieser AGB wird auf unbestimmte Zeit geschlossen. Es kann von jeder Partei in Textform mit einer Frist von sechs Monaten zum Monatsende gekündigt werden. Mit Wirksamwerden der Kündigung erlischt der Zugang des Vertreters zu der Online-Plattform von arztpool.',
        'Das Recht der Parteien zur Kündigung aus wichtigem Grund bleibt unberührt.',
        'Die Pflichten der Parteien gemäß Ziffer 7 (Vertraulichkeit) und 8 (Datenschutz) bestehen auch nach Beendigung des Vertrages fort.',
      ],
    },
    CONFIDENTIALITY: {
      HEADING: '§ 7 Vertraulichkeit',
      TEXT: [
        'Die Parteien verpflichten sich, sämtliche Unterlagen, Daten, Betriebs- und Geschäftsangelegenheiten und sonstigen Informationen, die ihnen von der jeweils anderen Partei übermittelt oder offenbart werden oder von denen die jeweilige Partei in sonstiger Weise Kenntnis erlangt, streng vertraulich zu behandeln; die Parteien verpflichten sich, die Unterlagen, Daten, Betriebs- und Geschäftsangelegenheiten und sonstige Informationen ausschließlich zum Zwecke der Durchführung der Geschäftsbeziehung zu verarbeiten und diese nicht für eigene oder Zwecke Dritter zu nutzen. Die Parteien haben – insbesondere unter Berücksichtigung des Stands der Technik – geeignete und angemessene technische und organisatorische Maßnahmen zu ergreifen, um die Einhaltung der Vertraulichkeitsverpflichtung sicherzustellen.',
        'Die Vertraulichkeitsverpflichtung nach Ziffer 7.1 gilt ausnahmsweise nicht, a) wenn und soweit Unterlagen, Daten, Betriebs- und Geschäftsangelegenheiten oder sonstige Informationen bereits offenkundig sind oder nach Abschluss des jeweiligen Vermittlungsvertrages offenkundig werden, ohne dass die jeweilige Partei gegen die Vertraulichkeitsverpflichtung verstoßen hat; b) wenn und soweit die jeweils berechtigte Partei die jeweils andere Partei durch vorherige schriftliche (§ 126 BGB) Zustimmung von der Vertraulichkeitsverpflichtung entbunden hat sowie c) wenn und soweit eine Partei aufgrund einer zwingenden gesetzlichen Bestimmung, einer gerichtlichen Entscheidung oder einer behördlichen Anordnung zur Offenlegung von Unterlagen, Daten, Geschäfts- und Betriebsangelegenheiten oder sonstigen Informationen verpflichtet ist. In diesem Fall hat diejenige Partei, die zur Offenlegung verpflichtet ist, die jeweils andere Partei darüber unverzüglich in Textform zu informieren, sofern dies nicht durch eine zwingende gesetzliche Bestimmung, eine gerichtliche Entscheidung oder behördliche Anordnung untersagt ist.',
      ],
    },
    DATA_PROTECTION: {
      HEADING: '§ 8 Datenschutz',
      TEXT: [
        'Zum Zwecke der Vertragsdurchführung verarbeitet arztpool personenbezogene Daten des Vertreters und übermittelt zu diesem Zweck insbesondere personenbezogene Daten an den Kunden. arztpool verpflichtet sich, die jeweils geltenden datenschutzrechtlichen Bestimmungen zu beachten.',
        `Die jeweils gültige Datenschutzbestimmungen sind unter <a href="${
          import.meta.env.ARZTPOOL_META_BASE_URL
        }privacy" target="_blank">arztpool24.de/privacy</a> einsehbar.`,
      ],
    },
    CHANGES_TO_TERMS_AND_CONDITIONS: {
      HEADING: '§ 9 Änderungen an den AGB',
      TEXT: [
        'Änderungen dieser AGB werden dem Vertreter spätestens 30 Tage vor dem Zeitpunkt ihres Wirksamwerdens in Textform angeboten. Die Änderungen können auch im Vermittlungsportal von arztpool angeboten werden. Der Vertreter kann den Änderungen vor dem vorgeschlagenen Zeitpunkt ihres Wirksamwerdens wahlweise zustimmen oder sie ablehnen.',
        'Die Zustimmung des Vertreters gilt als erteilt, wenn er seine Ablehnung nicht mindestens 14 Tage vor dem Zeitpunkt des Wirksamwerdens der Änderungen angezeigt hat. Auf diese Genehmigungswirkung wird arztpool bei der Übermittlung des Angebots besonders hinweisen.',
      ],
    },
    FINAL_CLAUSES: {
      HEADING: '§ 10 Schlussbestimmungen',
      TEXT: [
        'Änderungen und Ergänzungen dieser AGB, die Erklärung einer Kündigung sowie die Änderung dieser Klausel bedürfen zu ihrer Wirksamkeit der Schriftform. Die Ersetzung der Schriftform durch die elektronische Form oder die Textform ist ausgeschlossen.',
        'Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise unwirksam oder undurchführbar sein oder werden, so berührt dies die Wirksamkeit der übrigen Bestimmungen nicht.',
        'Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.',
        'Gerichtsstand ist Berlin.',
      ],
    },
  },
};
