export default {
  HEADLINE: 'Rechnungsvorlage',
  LABEL: [
    'Steuernummer: {taxNumber}',
    'Rechnungsnummer: ',
    'Datum: {currentDate}',
  ],
  SUBJECT: 'Rechnung - Honorar',
  TEXT: [
    'Sehr geehrte Damen und Herren,',
    'für meine erbrachten ärztlichen Leistungen, im Rahmen von KV-Vertretungen, stelle ich Ihnen wie folgt in Rechnung:',
    'Pos.',
    'Bezeichnung',
    'Menge',
    'Einzelpreis',
    'Summe',
    '1',
    'KV-Dienst von {startDate} bis {endDate}',
    'Honorar für reguläre Dienststunden',
    '2',
    'Leistungsbezogene, gestaffelte Zusatzvergütung für Hausbesuchsdienste pro gesetzlich versicherter Patient',
    'Zu zahlender Betrag: {fee}',
    'Leistungen für ärztliche Heilbehandlung sind von der Umsatzsteuer laut §4 Nr.14 Umsatzsteuergesetz (UStG) befreit.',
    'Bitte überweisen Sie den Betrag ohne Abzüge bis zum {dueDate} auf das Konto',
    'Kontoinhaber: {accountHolder}',
    'Institut: {bank}',
    'IBAN: {iban}',
    'BIC: {bic}',
    'Mit freundlichen Grüßen',
  ],
  LEGACY_TEXT: [
    'Extravergütung ab dem {patientLimit}. Patienten in {serviceDuration} Std.',
    'insgesamt {patientCount} Notfallscheine',
  ],
  BREAK_ADDENDUM: '(inkl. Pause von {breakDuration}h)',
};
