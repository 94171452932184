import { DocumentNode } from 'graphql/language';
import gql from 'graphql-tag';

export const USER_FRAGMENT: DocumentNode = gql`
  fragment userFields on user {
    id
    email
    title
    gender
    firstName
    lastName
    birthDate
    registrationDate
    mobile1
    mobile2
    fax
    phone
    activeContract
    suspendedContractFee
    legacy
    legacyInformation {
      bagHandlingFee
      bonusFee
    }
  }
`;

export const ADDRESS_FRAGMENT: DocumentNode = gql`
  fragment addressFields on address {
    id
    city
    street
    country
    houseNr
    zipCode
    state
  }
`;

export const MESSAGE_FRAGMENT: DocumentNode = gql`
  fragment publicMessageFields on message {
    id
    createdOn
    confirmedOn
    subject
    content
    metadata
    recipient_id
    needs_confirmation
    fromOperator
  }
`;

export const BANKACCOUNT_FRAGMENT: DocumentNode = gql`
  fragment bankaccountFields on bank_account {
    id
    accountHolder
    bank
    iban
    bic
  }
`;

export const DEBTOR_FRAGMENT: DocumentNode = gql`
  fragment debtorFields on debtor {
    id
    debtorNumber
    billingAddress {
      ...addressFields
    }
    payment_method {
      ... on debtor_bill_payment_method {
        id
        debtor {
          id
          debtorNumber
          billingAddress {
            ...addressFields
          }
        }
        bankAccount {
          ...bankaccountFields
        }
      }
      ... on debtor_sepa_payment_method {
        id
        debtor {
          id
          debtorNumber
          billingAddress {
            ...addressFields
          }
        }
        bankAccount {
          ...bankaccountFields
        }
      }
    }
    tariff {
      id
      period
      validTo
      payment_method {
        ... on debtor_bill_payment_method {
          id
          debtor {
            id
            debtorNumber
            billingAddress {
              ...addressFields
            }
          }
          bankAccount {
            ...bankaccountFields
          }
        }
        ... on debtor_sepa_payment_method {
          id
          debtor {
            id
            debtorNumber
            billingAddress {
              ...addressFields
            }
          }
          bankAccount {
            ...bankaccountFields
          }
        }
      }
    }
  }
`;

export const SERVICEAREA_FRAGMENT: DocumentNode = gql`
  fragment serviceareaFields on servicearea {
    id
    name
    validFrom
    validTo
    state
    mobileServices {
      id
      name
      shorthandName
      coordinates
      comment
    }
    officeServices {
      id
      name
      shorthandName
      comment
      address {
        ...addressFields
      }
    }
  }
`;

export const SUBMISSIONS_FRAGMENT: DocumentNode = gql`
  fragment submissionFields on service_submission {
    id
    content
    establishment {
      id
    }
    service {
      id
    }
  }
`;

export const SERVICE_FRAGMENT: DocumentNode = gql`
  fragment serviceFields on service {
    id
    createdBy
    createdOn
    start
    end
    kind
    concluded
    legacy_bonus_fee
    comment
    establishmentDoctor {
      ...doctorFields
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      min
      max
      current
    }
    holidayFeeRange {
      min
      max
      current
    }
    substitute {
      details {
        user {
          ...userFields
        }
        address {
          ...addressFields
        }
        debtor {
          ...debtorFields
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        specialization
        certificate {
          url
        }
      }
    }
  }
`;

export const SERVICE_FRAGMENT_GUEST: DocumentNode = gql`
  fragment serviceFieldsGuest on service {
    id
    createdBy
    createdOn
    start
    end
    kind
    legacy
    comment
    instantAssign
    priority
    bookmarksCount
    applicationsCount
    kvShareRateMobile
    kvShareRateOffice
    break {
      start
      end
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      min
      max
      current
    }
    holidayFeeRange {
      min
      max
      current
    }
  }
`;

export const SERVICE_FRAGMENT_PUBLIC_MAX: DocumentNode = gql`
  fragment publicServiceFields on service {
    id
    createdOn
    createdBy
    start
    end
    kind
    legacy
    legacy_bonus_fee
    comment
    bookmarksCount
    applicationsCount
    kvShareRateMobile
    kvShareRateOffice
    substituteConfirmedServiceInfoOn
    instantAssign
    priority
    allinclusive
    service_tier
    stateInsuredPatientCount
    consultationViaTelephoneCount
    privatePatientCount
    necropsyCount
    break {
      start
      end
    }
    emergencynotes {
      __typename
      id
      user_comment
      category
      approved_date
      submitted_date
      uploaded_date
      client_comment
      declined_date
      user_comment
      Document {
        url
        filename
      }
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      current
      max
      min
    }
    holidayFeeRange {
      current
    }
  }
`;

export const SERVICE_FRAGMENT_PUBLIC: DocumentNode = gql`
  fragment publicServiceFields on service {
    id
    createdBy
    createdOn
    start
    end
    kind
    legacy
    legacy_bonus_fee
    comment
    bookmarksCount
    applicationsCount
    kvShareRateMobile
    kvShareRateOffice
    substituteConfirmedServiceInfoOn
    instantAssign
    priority
    allinclusive
    service_tier
    stateInsuredPatientCount
    consultationViaTelephoneCount
    privatePatientCount
    necropsyCount
    break {
      start
      end
    }
    emergencynotes {
      __typename
      id
      user_comment
      category
      approved_date
      submitted_date
      uploaded_date
      client_comment
      declined_date
      user_comment
      Document {
        url
        filename
      }
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      min
      max
    }
  }
`;

export const BOOKMARK_FRAGMENT: DocumentNode = gql`
  fragment bookmarkFields on service {
    allinclusive
    applicationsCount
    bookmarksCount
    end
    id
    instantAssign
    kvShareRateMobile
    kvShareRateOffice
    priority
    published
    regularFeeRange {
      current
    }
    start
    createdOn
  }
`;

export const APPLICATION_FRAGMENT: DocumentNode = gql`
  fragment applicationFields on service {
    allinclusive
    applicationsCount
    bookmarksCount
    end
    id
    instantAssign
    kvShareRateMobile
    kvShareRateOffice
    priority
    published
    regularFeeRange {
      current
    }
    start
    createdOn
  }
`;

export const SERVICE_FRAGMENT_SERVICE_DETAIL_PAGE: DocumentNode = gql`
  fragment serviceFieldsServiceDetailPage on service {
    id
    createdBy
    createdOn
    published
    concluded
    start
    end
    kind
    comment
    legacy_bonus_fee
    bookmarksCount
    applicationsCount
    kvShareRateMobile
    kvShareRateOffice
    instantAssign
    priority
    allinclusive
    break {
      start
      end
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      min
      max
      current
    }
    substitute {
      details {
        id
      }
    }
  }
`;

export const SERVICE_FRAGMENT_PARTNER: DocumentNode = gql`
  fragment serviceFieldsPartner on service {
    id
    createdBy
    createdOn
    published
    concluded
    start
    end
    kind
    comment
    orderNumber
    stateInsuredPatientCount
    privatePatientCount
    bookmarksCount
    applicationsCount
    kvShareRateMobile
    kvShareRateOffice
    instantAssign
    priority
    allinclusive
    service_tier
    necropsyCount
    consultationViaTelephoneCount
    partnerConfirmedContractOn
    substituteConfirmedContractOn
    countsConfirmedOn
    break {
      start
      end
    }
    establishmentDoctor {
      ...doctorFields
    }
    establishment {
      id
      billingAddress {
        ...addressFields
      }
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      min
      max
      current
    }
    holidayFeeRange {
      min
      max
      current
    }
    substitute {
      details {
        id
        license {
          url
        }
        user {
          id
          email
          title
          firstName
          lastName
          birthDate
          mobile1
          mobile2
          fax
          phone
        }
        address {
          city
          street
          country
          houseNr
          zipCode
          state
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        specialization
        certificate {
          url
        }
      }
    }
  }
`;

export const SERVICE_FRAGMENT_PARTNER_URL: DocumentNode = gql`
  fragment serviceUrlsPartner on service {
    id
    substitute {
      details {
        license {
          url
        }
        user {
          email
          title
          firstName
          lastName
          birthDate
          mobile1
          mobile2
          fax
          phone
        }
        address {
          city
          street
          country
          houseNr
          zipCode
          state
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        specialization
        certificate {
          url
        }
      }
    }
  }
`;

export const SERVICE_FRAGMENT_SUBSTITUTE: DocumentNode = gql`
  fragment serviceFieldsSubstitute on service {
    id
    createdBy
    createdOn
    concluded
    start
    end
    kind
    comment
    orderNumber
    billNumber
    legacy_bonus_fee
    break {
      start
      end
    }
    establishmentDoctor {
      name
      lanr
    }
    establishment {
      name
      billingAddress {
        ...addressFields
      }
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      current
    }
    holidayFeeRange {
      current
    }
  }
`;

export const SERVICE_FRAGMENT_ASSIGNED_SUBSTITUTE: DocumentNode = gql`
  fragment assignedServiceFields on service {
    id
    createdBy
    createdOn
    start
    end
    kind
    concluded
    comment
    legacy
    legacy_bonus_fee
    orderNumber
    billNumber
    stateInsuredPatientCount
    privatePatientCount
    necropsyCount
    consultationViaTelephoneCount
    partnerConfirmedContractOn
    substituteConfirmedContractOn
    countsConfirmedOn
    break {
      start
      end
    }
    establishment {
      name
      billingAddress {
        ...addressFields
      }
    }
    establishmentDoctor {
      name
      lanr
    }
    serviceArea {
      ...serviceareaFields
    }
    regularFeeRange {
      current
    }
    holidayFeeRange {
      current
    }
  }
`;

export const DOCTOR_FRAGMENT: DocumentNode = gql`
  fragment doctorFields on establishment_doctor {
    id
    name
    kvSeats
    lanr
  }
`;

export const APPLICANTS_FRAGMENT: DocumentNode = gql`
  fragment applicants on service {
    id
    createdOn
    applicants {
      offer
      details {
        id
        has_doctors_bag
        use_default_contract
        user {
          id
          firstName
          lastName
          registrationDate
        }
      }
    }
    __typename
  }
`;

export const SUBSTITUTE_FRAGMENT: DocumentNode = gql`
  fragment substituteFields on substitute {
    id
    taxNumber
    trainingConfirmed
    verified
    use_default_contract
    has_doctors_bag
    blackListedStates
  }
`;

export const SERVICE_MANAGEMENT_FRAGMENT_PARTNER: DocumentNode = gql`
  fragment serviceManagementFields on service {
    id
    createdOn
    createdBy
    start
    end
    kind
    orderNumber
    instantAssign
    priority
    allinclusive
    kvShareRateMobile
    kvShareRateOffice
    service_tier
    billNumber
    stateInsuredPatientCount
    privatePatientCount
    necropsyCount
    consultationViaTelephoneCount
    published
    concluded
    partnerConfirmedContractOn
    substituteConfirmedServiceInfoOn
    substituteConfirmedContractOn
    countsConfirmedOn
    break {
      start
      end
    }
    establishmentDoctor {
      id
      name
      lanr
      partner {
        legacy
        legacyInformation {
          bonusFee
        }
        email
        contactEmail
      }
    }
    serviceArea {
      name
      state
    }
    establishment {
      name
      billingAddress {
        city
        street
        country
        houseNr
        zipCode
        state
      }
      ownedBy {
        id
        bsnr
        name
        type
        address {
          city
          street
          country
          houseNr
          zipCode
          state
        }
      }
    }
    substitute {
      details {
        license {
          url
        }
        id
        verified
        has_doctors_bag
        user {
          email
          title
          firstName
          lastName
          birthDate
          mobile1
          mobile2
          fax
          phone
        }
        address {
          city
          street
          country
          houseNr
          zipCode
          state
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        specialization
        certificate {
          url
        }
      }
    }
    regularFeeRange {
      min
      max
      current
    }
    applicationsCount
    bookmarksCount
    applicants {
      offer
      details {
        id
        verified
        has_doctors_bag
        user {
          title
          firstName
          lastName
        }
      }
      specializations {
        verified
        specialization
      }
    }
  }
`;

export const SERVICE_SUBSTITUTE_DOCUMENTLINKS_FRAGMENT_PARTNER: DocumentNode = gql`
  fragment serviceSubstituteDocumentFields on service {
    substitute {
      details {
        license {
          url
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        certificate {
          url
        }
      }
    }
  }
`;

export const SERVICE_MANAGEMENT_FRAGMENT_SUBSTITUTE: DocumentNode = gql`
  fragment serviceManagementFields on service {
    id
    createdOn
    createdBy
    start
    end
    kind
    orderNumber
    priority
    billNumber
    kvShareRateMobile
    kvShareRateOffice
    service_tier
    billNumber
    stateInsuredPatientCount
    privatePatientCount
    necropsyCount
    consultationViaTelephoneCount
    published
    concluded
    partnerConfirmedContractOn
    substituteConfirmedServiceInfoOn
    substituteConfirmedContractOn
    countsConfirmedOn
    legacy_bonus_fee
    card_reader_at
    break {
      start
      end
    }
    establishmentDoctor {
      name
      lanr
      partner {
        legacy
        legacyInformation {
          bonusFee
        }
        email
        contactEmail
      }
    }
    serviceArea {
      name
      state
      officeServices {
        id
        name
        shorthandName
        address {
          id
          city
          street
          country
          houseNr
          zipCode
          state
        }
      }
    }
    establishment {
      name
      billingAddress {
        city
        street
        country
        houseNr
        zipCode
        state
      }
    }
    substitute {
      details {
        id
        taxNumber
        user {
          id
          email
          title
          firstName
          lastName
          birthDate
          mobile1
          mobile2
          fax
          phone
        }
        address {
          city
          street
          country
          houseNr
          zipCode
          state
        }
        bankAccount {
          iban
          bank
        }
      }
      liabilityInsurance {
        url
      }
      specializations {
        specialization
        certificate {
          url
        }
      }
    }
    regularFeeRange {
      min
      max
      current
    }
    applicationsCount
    bookmarksCount
  }
`;
