export default {
  HEADING: 'Nutzerverwaltung',
  TABLE_HEADERS: {
    ROLES: 'Rollen',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    DEBTOR_NUMBER: 'Kundennummer',
    EMAIL: 'E-Mail',
    INSTITUTION_NAME: 'Institution',
    VERIFIED: 'Verifiziert',
    ACTIONS: 'Aktionen',
  },
  FORM_LABELS: {
    SEARCH_TERM: 'Suche in Institution, Vorname, Nachname, E-Mail und Kundennummer...',
    ROLE: 'Rolle',
    VERIFIED: 'Verifiziert',
    NEW_USER: 'Neuer Nutzer',
    PARTNERS_MASS_EMAILS: 'Kunden Mass Mailing',
    SELECT_VALUES: {
      ROLES_LIST: [
        'Alle',
        'Kunden',
        'Vertreter',
      ],
      VERIFIED_LIST: [
        'Alle',
        'Verifiziert',
        'Nicht Verifiziert',
      ],
    },
    SUBMIT: 'Suchen',
  },
  SINGLE_USER: {
    HEADING: 'Nutzer',
    ROLE: 'Rolle: ',
    CONTRACT_TYPE: 'Vertragsmodell: ',
    LEGACY_CONTRACT: 'Altmodell',
    NEW_CONTRACT: 'Neumodell (Mokaflor)',
    VANILLA_CONTRACT: 'Neumodell (Vanilla)',
    MOBILE_SERVICE: 'Fahrdienst',
    OFFICE_SERVICE: 'Sitzdienst',
    CONTRACT_DATA: 'Vertragsdaten',
    HISTORY: 'History',
    CONTRACT_HISTORY_ENTRY:
      'Folgende Änderungen vorgenommen: Vertragsstatus auf { contractStatus }, Ruhegebühr auf { dormantFee }, Extravergütung auf { bonusFee }',
    ORDER_SUMMARY_HISTORY_ENTRY: 'Für den Servicezeitraum generierte Auftragsübersicht { period }',
    ORDER_CONFIRMATION_HISTORY_ENTRY: 'Für den Servicezeitraum generierte Auftragsbestätigung { period }',
    NO_RESULTS: 'Keine Ergebnisse',
    SERVICE_TABLE_HEADERS_PARTNER: {
      TYPE: 'Dienstart',
      ESTABLISHMENT: 'Betriebsstätte',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      SUBSTITUTE: 'Vertreter',
      ACTIONS: 'Aktionen',
    },
    SERVICE_TABLE_HEADERS_SUSBTITUTE: {
      TYPE: 'Dienstart',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      ACTIONS: 'Aktionen',
    },
    FORM_LABELS: {
      TITLE: 'Titel',
      GENDER: 'Geschlecht',
      FIRST_NAME: 'Vorname',
      LAST_NAME: 'Nachname',
      EMAIL: 'eMail',
      CONTACT_EMAIL: 'Kontakt eMail',
      PHONE: 'Festnetz',
      MOBILE_1: 'Handy 1',
      MOBILE_2: 'Handy 2',
      FAX: 'Faxnummer',
      BIRTHDATE: 'Geburtsdatum',
      SHOW_VANILLA_CONTRACT: 'Alten Rahmenvertrag anzeigen',
      LEGACY: {
        CONTRACT: {
          LABEL: 'Vertragsstatus',
          ACTIVE: 'aktiv',
          DORMANT: 'ruhend',
        },
        BONUS_FEE: 'Extravergütung',
        DORMANT_FEE: 'Ruhend Pauschale',
        LEGACY_DEBTOR_NUMBER: 'Legacy Kundennummer',
        BAG_HANDLING_FEE: 'Kofferpauschale',
        SUSPENDED_CONTRACT_FEE: 'Ruhend Pauschale ',
        POWER_OF_ATTORNEY: 'Vollmacht',
        FRAMEWORK_AGREEMENT: 'Rahmenvertrag',
      },
      COPY: 'Kopieren',
      COPIED_SUCCESSFULLY: 'Erfolgreich kopiert',
    },
    PARTNER: {
      INSTITUTION: 'Einrichtung',
      ESTABLISHMENTS: 'Betriebsstätten',
      ADDRESS: 'Adresse',
      BILLING_ADDRESS: 'Rechnungsadresse',
      BANK_DATA: 'Bankdaten',
      INSTITUTION_MVZ: 'MVZ',
      INSTITUTION_SINGLE: 'Einzelpraxis',
      KV_SEATS: 'KV Sitze insgesamt',
      IS_VERIFIED: 'Einrichtung bereits verifiziert.',
      BANK: 'Bankverbindung',
      SERVICE_AREA: 'Dienstgebiet',
      SERVICES: 'Dienste',
      NO_SERVICE_AREA: 'Noch kein Dienstgebiet ausgewählt',
      ESTABLISHMENT_DOCTORS: 'Betriebsstättenärzte',
      NEW_ESTABLISHMENT_DOCTOR: 'Betriebsstättenarzt hinzufügen',
      NEW_ESTABLISHMENT: 'Betriebsstätte hinzufügen',
      DELETE_DOCTOR_WARNING_TITLE: 'Löschen?',
      DELETE_DOCTOR_WARNING_MESSAGE: 'Diesen Betriebsstättenarzt löschen?',
      DELETE_ESTABLISHMENT_WARNING_MESSAGE: 'Diese Betriebsstätte löschen?',
      LOGISTICS: 'Logistik',
      REQUIRES_DOCTORS_BAG_DELIVERY: 'Arzttasche Lieferservice',
      REQUIRES_FOLDER_DELIVERY: 'Dienstformulare Lieferservice',
      REQUIRES_CARD_READER: 'Kartenlesegrät',
      SERIAL_NUMBER: 'Seriennummer',
      PAYMENT_METHOD: 'Zahlweise',
      FORM_LABELS: {
        NAME: 'Name',
        ESTABLISHMENT_NAME: 'Betriebsstättenname',
        BSNR: 'Betriebsstättennummer',
        STREET: 'Straße',
        HOUSENUMBER: 'Hausnummer',
        ZIP: 'Postleitzahl',
        CITY: 'Ort',
        STATE: 'Bundesland',
        ACCOUNT_HOLDER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        LANR: 'LANR',
        KV_SEATS: 'KV Sitze',
        SUBMIT: 'Speichern',
        SEARCH: 'Suchen',
        EDIT: 'Editieren',
        FROM: 'Von',
        TO: 'Bis',
        SERVICE_AREA: 'Dienstgebiet',
        BANKACCOUNT: {
          ACCOUNT_HOLDER: 'Kontoinhaber',
          BANK: 'Bank',
          BIC: 'BIC',
          IBAN: 'IBAN',
        },
        DOWNLOAD: 'Download',
        DELETE: 'Löschen',
        VERIFY: 'Einrichtung verifizieren',
        BILL_PAYMENT_METHOD: 'Dieser Kunde zahlt via Rechnung. Bei der Änderung zur SEPA Zahlweise wird die oben definierte Bankverbindung genutzt.',
        SEPA_PAYMENT_METHOD: 'Dieser Kunde zahlt via SEPA. Bei der Änderung zur Rechnungszahlweise wird die oben genannte Bankverbindung nicht gelöscht.',
        SWITCH_TO_BILL_PAYMENT: 'Zur Zahlung via Rechnung wechseln.',
        SWITCH_TO_SEPA_PAYMENT: 'Zur Zahlung via SEPA wechseln.',
        SEPA_EXPIRATION_DATE: 'SEPA Ablaufdatum festlegen',
      },
      SERVICE_AREA_MODAL: {
        HEADING: 'Dienstgebiet bearbeiten',
        DESCRIPTION: 'Wählen Sie das Bundesland, in dem diese Betriebsstätte liegt und anschließend das passende Dienstgebiet aus.',
        CLOSE: 'Schließen',
      },
    },
    SUBSTITUTE: {
      MEMO: 'Memo (intern)',
      ADDRESS: 'Adresse',
      BILLING_ADDRESS: 'Rechnungsadresse',
      MAILING_BLACKLIST: 'Blockliste Dienstbenachrichtigungen',
      BLACKLIST_DESCRIPTION: [
        'Mit diesen Einstellungen können Sie die Benachrichtigung über Dienste für diesen Vertreter in einzelnen Bundesländern sperren.',
        'Bitte beachten: Für die hier aktivierten Bundesländer bekommt der Vertreter KEINE Mails, wenn für die betreffenden Bundesländer Dienstbenachrichtigungen versandt werden.',
        'Da die Vertreter sich selber organisieren sollen, ist diese Einstellung nur für spezielle Fälle gedacht, wo Sie als Operator eingreifen müssen (KV in einem Bundesland sperrt einen Vertreter o.ä.).',
      ],
      BANKACCOUNT: 'Bankverbindung & Steuernummer',
      SPECIALIZATION: 'Fachrichtung',
      SPECIALIZATION_VERIFIED: 'Fachrichtung bereits verifiziert',
      VERIFY_SPECIALIZATION: 'Facharzt verifizieren',
      TRAINING_CONFIRMED: 'Der/die Vertreter*in hat uns bestätigt, dass er/sie sich mind. im 3. Jahr seiner/ihrer Weiterbildung befinden.',
      CONFIRM_TRAINING_TEXT: 'Befindet sich der/die Vertreter*in mind. im 3. Jahr seiner/ihrer Weiterbildung?',
      CONFIRM_TRAINING: 'Weiterbildung bestätigen',
      INSURANCES: 'Versicherungen',
      NEW_INSURANCE: 'Neue Versicherung eintragen',
      APPLICATIONS: 'Bewerbungen',
      ASSIGNMENTS: 'Dienste',
      MOBILE_SERVICES: 'Fahrdienste',
      OFFICE_SERVICES: 'Sitzdienste',
      LICENSE_PRESENT: 'Approbation bereits hochgeladen',
      LICENSE_NOT_PRESENT: 'Approbation noch nicht hochgeladen',
      SPECIALIZATION_CERTIFICATE_PRESENT: 'Facharzturkunde bereits hochgeladen',
      SPECIALIZATION_CERTIFICATE_NOT_PRESENT: 'Facharzturkunde noch nicht hochgeladen',
      VERIFY: 'Vertreter verifizieren',
      VERIFY_DESCRIPTION:
        'Mit der Verifikation dieses Vertreters, kann dieser von Kunden aus der Bewerberliste ausgewählt werden. Bitte nur verifzieren, wenn vorher eine eingängige Prüfung der Approbation und der Facharzturkunde stattgefunden hat.',
      IS_VERIFIED: 'Vertreter wurde bereits verifiziert',
      PAYMENT_METHOD: 'Zahlweise',
      FORM_LABELS: {
        MEMO: 'Memo - dies ist ein internes Feld und wird dem Nutzer nicht angezeigt',
        NAME: 'Name',
        STATE: 'Bundesland',
        VALID_FROM: 'Gültig von',
        VALID_TO: 'Gültig bis',
        FROM: 'Von',
        TO: 'Bis',
        SHORTHAND: 'Kürzel',
        COORDINATES: 'Koordinaten',
        COMMENT: 'Kommentar',
        LEGACY_ID: 'Legacy ID',
        CITY: 'Stadt',
        ZIP: 'PLZ',
        STREET: 'Straße',
        HOUSENUMBER: 'Hausnummer',
        ACCOUNT_HOLDER: 'Kontoinhaber',
        IBAN: 'IBAN',
        BIC: 'BIC',
        BANK: 'Bank',
        TAX_NUMBER: 'Steuernummer',
        INSURANCE_NAME: 'Name der Versicherung',
        INSURANCE_NUMBER: 'Nummer der Police',
        DOCUMENT: 'Haftpflichtversicherungsurkunde',
        SPECIALIZATION: 'Fachrichtung',
        TRAINING_CONFIRMED: 'Drittes Ausbildungsjahr abgeschlossen',
        DOCTORS_BAG: 'Arzttasche vorhanden',
        STATES: 'Bundesländer',
        DOCUMENT_LICENSE: 'Approbation',
        DOCUMENT_SPECIALIZATION: 'Facharzturkunde',
        SUBMIT: 'Speichern',
        DOWNLOAD: 'Download',
        VERIFY: 'Vertreter verifizieren',
        BILL_PAYMENT_METHOD: 'Dieser Vertreter zahlt via Rechnung. Bei der Änderung zur SEPA Zahlweise wird die oben definierte Bankverbindung genutzt.',
        SEPA_PAYMENT_METHOD: 'Dieser Vertreter zahlt via SEPA. Bei der Änderung zur Rechnungszahlweise wird die oben genannte Bankverbindung nicht gelöscht.',
        SWITCH_TO_BILL_PAYMENT: 'Zur Zahlung via Rechnung wechseln.',
        SWITCH_TO_SEPA_PAYMENT: 'Zur Zahlung via SEPA wechseln.',
      },
    },
  },
  USER_ROLE_LABELS: {
    SUBSTITUTE: 'Vertreter',
    PARTNER: 'Kunde',
  },
  USER_VERIFIED_LABELS: {
    VERIFIED: 'Verifiziert',
    NON_VERIFIED: 'Nicht Verifiziert',
  },
  NEW_USER_MODAL: {
    CLOSE: 'Schließen',
    HEADING: 'Neuen Nutzer anlegen',
    TAB_PARTNER: 'Neuer Kunde',
    TAB_SUBSTITUTE: 'Neuer Vertreter',
    INSTITUTION_INFO: 'Einrichtung',
    INSTITUTION_ADDRESS: 'Adresse der Einrichtung',
    BANK_INFO: 'Kontoinformationen',
    ADDRESS_INFO: 'Adresse',
    SPECIALIZATION_INFO: 'Fachrichtung',
    ESTABLISHMENT_INFO: 'Betriebsstätte',
    FORM_LABELS: {
      EMAIL: 'E-Mail Adresse',
      TITLE: 'Titel',
      GENDER: 'Geschlecht',
      FIRSTNAME: 'Vorname',
      LASTNAME: 'Nachname',
      BIRTHDATE: 'Geburtsdatum',
      PHONE: 'Festnetz',
      MOBILE1: 'Mobiltelefon',
      MOBILE2: 'alternatives Mobiltelefon',
      FAX: 'Fax',
      SUBMIT_BUTTON: 'Speichern',
      ESTABLISHMENT_NAME: 'Betriebsstättenname',
      INSTITUTION_NAME: 'Einrichtungsname',
      BSNR: 'Betriebsstättennummer',
      STREET: 'Straße',
      HOUSENR: 'Hausnummer',
      ZIP: 'Postleitzahl',
      CITY: 'Ort',
      STATE: 'Bundesland',
      ACCOUNT_HOLDER: 'Kontoinhaber',
      IBAN: 'IBAN',
      BIC: 'BIC',
      BANK: 'Bank',
      TAXNUMBER: 'Steuernummer',
      SPECIALIZATION: 'Fachrichtung',
      MVZ: 'Einrichtung ist MVZ (Betriebsstätte anlegen)',
    },
  },
};
