export default {
  HEADING: 'Dienstgebiete',
  HEADING_SINGLE_SERVICE_AREA: 'Dienst Submissions',
  TABLE_HEADERS: {
    NAME: 'Name',
    STATE: 'Bundesland',
    OFFICE_SERVICES: 'Sitzdienste',
    MOBILE_SERVICES: 'Fahrdienste',
    ACTIONS: 'Aktionen',
  },
  FORM_LABELS: {
    STATE: 'Bundesland',
    SUBMIT: 'Suchen',
    NEW_AREA: 'Neues Dienstgebiet anlegen',
  },
  MODAL: {
    HEADING: 'Neues Dienstgebiet anlegen',
    CLOSE: 'Schließen',
  },
  SINGLE_SERVICEAREA: {
    HEADING: 'Dienstgebiet',
    HEADING_MOBILESERVICES: 'Fahrdienste',
    HEADING_NEW_MOBILESERVICE: 'Neuen Fahrdienst hinzufügen',
    HEADING_OFFICESERVICES: 'Sitzdienste',
    HEADING_NEW_OFFICESERVICE: 'Neuen Sitzdienst hinzufügen',
    NO_MOBILESERVICES: 'Noch keine Fahrdienste angelegt...',
    NO_OFFICESERVICES: 'Noch keine Sitzdienste angelegt...',
    DEACTIVATED_BY: 'Dieser Benutzer wurde bereits deaktiviert von',
    DEACTIVATED_ON: 'am',
    DEACTIVATED_BECAUSE: 'wegen',
    DEACTIVATE: 'Deaktivieren',
    DEACTIVATE_TITLE: 'Möchten Sie diesen Benutzer deaktivieren?',
    DEACTIVATE_REASON: 'Was ist der Grund?',
    DEACTIVATE_REASON_CANCELLED: 'Abgesagt',
    CONTRACT_CANCELLED: 'Vertrag gekündigt',
    DEACTIVATE_RESASONS_VERTRETER: {
      PARENTAL_LEAVE: 'Elternzeit / Mutterschutz',
      NOT_SHOW_UP: 'Nicht zur Arbeit erschienen',
      NOT_SUITABLE: 'Nicht geeignet',
      DECEASED: 'Verstorben',
      UNHAPPY_DOCTOR_POOL: 'Unzufrieden mit dem Ärztepool',
    },
    DEACTIVATE_REASONS_KUNDEN: {
      CLOSING_PRACTICE: 'Praxisaufgabe',
      RESTRUCTURING: 'Restrukturierung der Praxis',
      PARENTAL_LEAVE: 'Elternzeit / Mutterschutz',
      TRANSFER_OF_HEALTH_INSURANCE: 'Übertragung der Kassenzulassung',
      NO_OBLIGIED_HEALTH_INSURANCE: 'Keine Verpflichtung mehr zur Kassenzugehörigkeit',
      TRANSFER_OF_SERVICES: 'Übertragung der Leistungen an private Vertreter',
      COLLABORATION_WTIH_COMPETITORS: 'Zusammenarbeit mit Wettbewerbern',
      DECESED: 'Verstorben',
      UNHAPPY_DOCTOR_POOL: 'Unzufrieden mit dem Ärztepool',
      UNHAPP_REPRESENTATIVES: 'Unzufrieden mit Vertretern',
      TERMINATION: 'Kündigung',
    },
    FORM_LABELS: {
      NAME: 'Name',
      STATE: 'Bundesland',
      VALID_FROM: 'Gültig von',
      VALID_TO: 'Gültig bis',
      SHORTHAND: 'Kürzel',
      COORDINATES: 'Koordinaten',
      COMMENT: 'Kommentar',
      COMMENT_PLACEHOLDER: 'Dieser Kommentar ist öffentlich.',
      CONFIDENTIAL_COMMENT: 'Vertraulicher Kommentar',
      CONFIDENTIAL_COMMENT_PLACEHOLDER: 'Vertrauliche Informationen zu diesem Dienst',
      LEGACY_ID: 'Legacy ID',
      CITY: 'Stadt',
      ZIP: 'PLZ',
      STREET: 'Straße',
      HOUSENUMBER: 'Hausnummer',
      SUBMIT: 'Speichern',
      CANCEL: 'Abbrechen',
    },
  },
};
