export default {
  HEADING_SERVICES: 'Dienstverwaltung',
  HEADING_SERVICE_SUBMISSIONS: 'Dienst Submissions',
  HEADING_SERVICES_ALL_INCLUSIVE: 'Rundum-Sorglos Dienste',
  HEADING_TIER_SERVICES: 'Dienste nach Dringlichkeit',
  MOBILE_SERVICE: 'Fahrdienst',
  OFFICE_SERVICE: 'Sitzdienst',
  SEND_DOCUMENTS: {
    TITLE: 'Dokumente senden',
    INFO: 'Wir senden die Gebührenvereinbarung und die Umtauschbenachrichtigung an die folgende E-Mail-Adresse.',
    NOTICE: 'Tauschmeldung',
    CONTRACT: 'Servicevertrag',
  },
  CALENDAR_EXCEPTIONS: {
    HEADLINE: 'Zeitraum außerhalb des zulässigen Bereichs',
    DESCRIPTION: 'Der Zeitraum, den Sie erreichen möchten, liegt außerhalb der ausgewählten Filter.',
    CLOSE: 'Schließen',
  },
  CALENDAR_NO_RESULTS: 'Für diesen Zeitraum liegen keine Ergebnisse vor.',
  CALENDAR_VIEW: {
    MONTH: 'Monatlich',
    WEEK: 'Wöchentlich',
  },
  SERVICES_VIEW: {
    TABLE: 'Tabellenansicht',
    CALENDAR: 'Kalenderansicht',
  },
  SERVICES: {
    TABLE_HEADERS: {
      TYPE: 'Dienstart',
      PARTNER: 'Kunde',
      DEBTOR_NUMBER: 'Kundennummer',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      SUBSTITUTE: 'Vertreter',
      ORDER_NUMBER: 'Auftragsnummer',
      STATUS: 'Status',
      ACTIONS: 'Aktionen',
    },
  },
  SERVICESUBMISSIONS: {
    TABLE_HEADERS: {
      TYPE: 'Dienstart',
      PARTNER: 'Kunde',
      DEBTOR_NUMBER: 'Kundennummer',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      SUBSTITUTE: 'Vertreter',
      STATUS: 'Status',
      ACTIONS: 'Aktionen',
    },
  },
  SERVICES_ALL_INCLUSIVE: {
    TABLE_HEADERS: {
      TYPE: 'Dienstart',
      PARTNER: 'Kunde',
      DEBTOR_NUMBER: 'Kundennummer',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      SUBSTITUTE: 'Vertreter',
      STATUS: 'Status',
      ACTIONS: 'Aktionen',
    },
  },
  SERVICES_TIERED: {
    TIER_LABELS: [
      'Standard',
      'Eil',
      'Express',
    ],
    TABLE_HEADERS: {
      TYPE: 'Dienstart',
      PARTNER: 'Kunde',
      DEBTOR_NUMBER: 'Kundennummer',
      CREATED_ON: 'Eingetragen am',
      START: 'Dienststart',
      END: 'Dienstende',
      SERVICE_AREA: 'Dienstgebiet',
      STATE: 'Bundesland',
      SUBSTITUTE: 'Vertreter',
      STATUS: 'Status',
      TIER: 'Dringlichkeit',
      ACTIONS: 'Aktionen',
    },
  },
  SINGLE_SERVICE: {
    HEADING: 'Dienst',
    LEGACY: 'Altdienst',
    PUBLISHED: 'Ausgeschrieben',
    NOT_PUBLISHED: 'Nicht ausgeschrieben',
    ASSIGNED: 'Dienst zugewiesen',
    CONCLUDED: 'Dienst beendet',
    COMMENT: 'Kommentar (öffentlich)',
    MEMO: 'Memo (intern)',
    SERVICE_AREA: 'Dienstgebiet',
    SERVICE_AREA_COMMENT: 'Öffentlicher Kommentar zum Dienstgebiet',
    SERVICE_AREA_CONFIDENTIAL_COMMENT: 'Vertraulicher Kommentar zum Dienstgebiet',
    COMMENT_EMPTY: 'Kein Kommentar eingegeben.',
    PARTNER: 'Kunde',
    PARTNER_LABELS: {
      INSTITUTION: 'Einrichtung',
      BSNR: 'BSNR',
      LANR: 'LANR',
      DEBTOR_NUMBER: 'Kundennummer',
      ESTABLISHMENT: 'Betriebsstätte',
      ESTABLISHMENT_DOCTOR: 'zu vertretendender Arzt',
      ESTABLISHMENT_DOCTOR_LANR: 'LANR',
    },
    APPLICATIONS: 'Bewerbungen',
    SEARCH_APPLICANTS: 'Nach Bewerbern suchen, um sie hinzuzufügen.',
    ASSIGN_SERVICE_WARNING_MESSAGE: 'Diesen Dienst zuweisen?',
    UNASSIGN_SERVICE_WARNING_MESSAGE: 'Die Dienstzuweisung löschen?',
    REVOKE_APPLICATION_WARNING_MESSAGE: 'Diese Bewerbung löschen?',
    ADD_APPLICATION_WARNING_MESSAGE: 'Diesen Bewerber hinzufügen?',
    APPLICANT_VERIFICATION_FAILED: 'Der Bewerber ist nicht geeignet diesen Dienst zu vertreten, da die Verifizierung der Qualifikation fehlt.',
    CONCLUDE_SERVICE_WARNING_MESSAGE: 'Diesen Dienst abschließen?',
    PUBLISH_SERVICE_WARNING_MESSAGE: 'Diesen Dienst auf dem Marktplatz veröffentlichen?',
    DELETE_SERVICE_WARNING_MESSAGE: 'Diesen Dienst unwiderruflich löschen?',
    NO_APPLICATIONS: '(Noch) keine Bewerber vorhanden',
    SUBSTITUTE: 'Vertreter',
    SUBSTITUTE_DEBTORNUMBER: 'Kundennummer',
    NO_SUBSTITUTE: '(Noch) kein Vertreter gewählt',
    DOCUMENTS: 'Dokumente',
    DOCUMENTS_PARTNER: 'Dokumente Kunde',
    DOCUMENTS_SUBSTITUTE: 'Dokumente Vertreter',
    DOCUMENTS_ANNOUNCEMENT: 'Tauschmeldung',
    SEND_DOCUMENTS: 'Dokumente senden',
    SEND_DOCUMENTS_SUBTITLE: 'Dienstvertretungsvertrag & Tauschmeldung',
    OTHER_DOCUMENTS_AT_MASTER_SERVICE: 'Um alle Dokumente anzuzeigen, wechseln Sie zum Hauptdienst:',
    DOCUMENTS_CONTRACT: 'Dienstvertretungsvertrag ',
    DOCUMENTS_CONTRACT_CONFIRMED_ON: 'Vertrag zugestimmt am: {0}',
    DOCUMENTS_CONTRACT_NOT_CONFIRMED: 'Vertrag noch nicht zugestimmt.',
    DOCUMENTS_INVOICE: 'Rechnungsvorlage',
    DOCUMENTS_CONFIRM_CONTRACT: 'Vertrag bestätigen?',
    DOCUMENTS_UNCONFIRM_CONTRACT: 'Vertragsbestätigung zurückziehen?',
    COUNTS: 'Patienten- und Serviceinformationen',
    COUNTS_PARTNER: 'Bestätigung Patientenanzahlen Kunde',
    COUNTS_NOT_CONFIRMED: 'Bestätigung steht noch aus',
    COUNTS_CONFIRMED: 'Patientenanzahlen von Kunde bestätigt: {0}',
    COUNTS_SUBSTITUTE: 'Patientenanzahlen vom Vertreter',
    SERVICE_INFORMATION: 'Checkliste',
    INFORMATION_PARTNER: 'Checklisten Informationen vom Kunden',
    INFORMATION_SUBSTITUTE: 'Checkliste Bestätigung vom Vertreter',
    INFORMATION_NOT_CONFIRMED: 'Bestätigung steht noch aus.',
    INFORMATION_CONFIRMED: 'Checkliste vom Vertreter bestätigt: {0}',
    SERVICE_STATUS: 'Dienststatus',
    MESSAGES: 'Nachrichten',
    BREAK_TIMES: 'Pausenzeiten',
    FORM_LABELS: {
      SEARCH_TERM: 'Suche in Vorname, Nachname, E-Mail und Kundennummer...',
      ASSIGN_APPLICANT: 'Bewerber hinzufügen',
      APPLICANT_OFFER: 'Bewerber Honorarangebot',
      SUBSTITUTE_FEE: 'Vertreter Honorar',
      ASSIGN_SUBSTITUTE: 'Vertreter wählen',
      UNASSIGN_SUBSTITUTE: 'Vertreter entfernen',
      ESTABLISHMENT: 'Betriebsstätte',
      DOCTOR: 'Arzt',
      MEMO: 'Memo (intern)',
      COMMENT: 'Kommentar (öffentlich)',
      KIND: 'Dienstart',
      START: 'von (Datum)',
      END: 'bis (Datum)',
      BREAK_START: 'Pause von (Datum)',
      BREAK_END: 'Pause bis (Datum)',
      EMERGENCY_FEE: 'Notfallscheinbeteiligung',
      FEE: 'Honorar für den Vertreter',
      MIN: 'Mindesthonorar',
      MAX: 'Maximales Honorar',
      TIME_END: 'Endzeit',
      SUBMIT: 'Speichern',
      SEARCH: 'Suchen',
      SERVICE_SUMMARY: 'Dienstzusammenfassung',
      SERVICE_BILLING_SUMMARY: 'Abrechnungsdaten',
      ADVERTISEMENT_MAILER: 'Dienstausschreibungsmail',
      PUBLISH: 'Dienst veröffentlichen',
      CONCLUDE: 'Dienst abschließen',
      DELETE: 'Dienst löschen',
      SHOW_CONTRACT: 'Vertrag anzeigen',
      SHOW_ANNOUNCEMENT: 'Tauschmeldung anzeigen',
      SHOW_INVOICE: 'Rechnungsvorlage anzeigen',
      CONFIRM_CONTRACT_SUBSTITUTE: 'Vertrag für Vertreter bestätigen',
      CONFIRM_CONTRACT_PARTNER: 'Vertrag für Kunden bestätigen',
      UNCONFIRM_CONTRACT_SUBSTITUTE: 'Zustimmung Vertreter zurücknehmen',
      UNCONFIRM_CONTRACT_PARTNER: 'Zustimmung Kunde zurücknehmen',
      CONFIRM_SERVICE_INFORMATION_SUBSTITUTE: 'Checklisten Informationen für Vertreter bestätigen',
      CONFIRM_COUNTS_PARTNER: 'Patientenanzahlen für Kunden bestätigen',
      SERIVCE_INFOS_CARDREADER: 'Kartenlesegerät',
      SERIVCE_INFOS_DOCUMENTS: 'Medizinische Dokumente',
      SERIVCE_INFOS_MISC: 'Sonstiges',
      COUNTS_NECROPSY: 'Anzahl Totenscheine',
      COUNTS_STATE_INSURED: 'Anzahl Gesetztlich Versicherte',
      COUNTS_PRIVATE_INSURED: 'Anzahl Private Versicherte',
      COUNTS_TELEPHONE: 'Anzahl Telefonischer Beratungen',
      PARTNER_REQUIRES_DOCTORS_BAG_DELIVERY: 'Arzttasche Lieferservice',
      PARTNER_REQUIRES_FOLDER_DELIVERY: 'Dienstformulare Lieferservice',
      SUBSTITUTE_HAS_DOCTORS_BAG: 'Arzttasche vorhanden',
      PARTNER_REQUIRES_CARD_READER: 'Kartenlesegerät',
      CARD_READER_SERIAL_NUMBERS: 'Kartenlesegerätnummer',
      LOGISTICS_STATUS: 'Logistikstatus',
    },
    LOGISTICS: 'Logistik',
    LOGISTICS_HANDLED_BY_MASTER_SERVICE: 'Die Logistik wird über den übergeordneten Dienst verwaltet',
    MASTER_SERVICE_ID: 'Übergeordneter-Dienst ID',
    SERVICE_CHAIN: 'Dienstkette',
  },
  NEW_SERVICE_MODAL: {
    HEADING: 'Neuen Dienst eintragen',
    HEADING_SEARCH_PARTNER: 'Kunden suchen, für den der Dienst eingetragen werden soll',
    DEFINE_SERVICE: 'Dienst bestimmen',
    DEFINE_SERVICE_DATES: 'Rahmendaten des Dienstes',
    DEFINE_SERVICE_BREAK_DATES: 'Pausendaten des Dienstes',
    DEFINE_FEES: 'Honorar und Beteiligung',
    DEFINE_OPTIONS: 'Zusatzoptionen',
    OFFICE_SERVICE: 'Sitzdienst',
    MOBILE_SERVICE: 'Fahrdienst',
    CLOSE: 'Schließen',
    FORM_LABELS: {
      SEARCH_TERM: 'Suche in Vorname, Nachname, E-Mail und Kundennummer...',
      ESTABLISHMENT: 'Betriebsstätte',
      DOCTOR: 'Arzt',
      KIND: 'Dienstart',
      TYPE: 'Diensttyp',
      MOBILE: 'Fahrdienst wählen',
      OFFICE: 'Sitzdienst wählen',
      START: 'von (Datum)',
      BREAK_START: 'Pause von (Datum)',
      END: 'bis (Datum)',
      BREAK_END: 'Pause bis (Datum)',
      EMERGENCY_FEE: 'Notfallscheinbeteiligung',
      FEE: 'Honorar für den Vertreter',
      MIN: 'Mindesthonorar',
      MAX: 'Maximales Honorar',
      TIME_END: 'Endzeit',
      SUBMIT: 'Speichern',
      SEARCH: 'Suchen',
      ASSIGN_PARTNER: 'Kunden wählen',
      INSTANT_APPLY: 'Sofortzusage aktivieren',
      PRIORITY: 'Prioritätslisting aktivieren',
      ALL_INCLUSIVE: 'Rundum-Sorglos-Paket aktivieren',
    },
  },
  SERVICE_BILLING_SUMMARY_MODAL: {
    SERVICE_DATA: 'Dienstdaten',
    PARTNER_DATA: 'Kundendaten',
    SUBSTITUTE_DATA: 'Vertreterdaten',
    CONTRACT_MODEL: 'Vertragsmodell',
    CONTRACT_MODEL_LEGACY: 'Altes Vertragsmodell',
    CONTRACT_MODEL_NEW: 'Neues Vertragsmodell',
    DOWNLOAD: 'Abrechnungsdaten als PDF exportieren',
    FEE: 'Honorarbetrag',
    COMPLETE_FEE: 'Gesamt Honorar',
    SERVICE_FEE: 'Vermittlungsgebühr',
    SERVICE_DURATION: 'Dienstdauer (exkl. Pausenzeiten)',
    PROVISION: 'Provision',
    STATEPATIENT_COUNT: 'Anzahl gesetzlich versichterter Patienten',
    STATEPATIENT_BONUS: 'Extravergütung gesetzlich versichterte Patienten',
    PARTNER_FEE: 'Vermittlungsgebühr',
    TIER_1: 'Standard',
    TIER_2: 'Eil',
    TIER_3: 'Express',
    SUBSTITUTE_FEE: 'Dienstgebühr',
    ALL_INCLUSIVE_FEE: 'Gebühr für RUSP',
    PRIORITY_FEE: 'Priority Aufschlag',
  },
  PARTNER_MASS_EMAILS_MODAL: {
    HEADING: 'Kunden-Massenmail',
    DESCRIPTION: [
      'Mit dieser Funktion können Sie E-Mails an alle Kunden oder an Kunden aus ausgewählten Bundesländern versenden.',
      'Eine Anrede und Grußformel werden automatisch in die E-Mail eingesetzt. Den Rest können Sie frei bearbeiten:',
    ],
    CLOSE: 'Schließen',
    ATTACHMENTS: 'Attachments',
    FORM_LABELS: {
      STATE: 'Bundesland',
      ALL_STATES: 'An alle Bundesländer',
      SEND_TO_STATE: 'Massenversand nach Bundesland',
      SEND_TO_SPECIFIC: 'Versand an einen einzelnen Kunden',
      CHOOSE_PARTNER: 'Empfänger setzen',
      RECIPIENT: 'Empfänger: ',
      SUBJECT: 'Betreff',
      FORCE: 'Die Ausschreibungsmail auch Nutzern zustellen, die dieses Bundesland abgewählt haben.',
      MESSAGE: 'Ausschreibungsnachricht',
      WARNING_MESSAGE: 'Ausschreibungsmail versenden?',
      SUBMIT: 'Abschicken',
      SUBMIT_CONFIRM: 'Wirklich abschicken?',
      SUBMIT_SUCCESS: 'E-Mail versand. Sie können das Fenster jetzt schließen.',
      SUBMIT_ERROR: 'Es gab ein Problem beim E-Mail-Versand. Bitte versuchen Sie es später nocheinmal.',
    },
    ERRORS: {
      NO_PARTNER_SELECTED: 'Bitte einen Empfänger auswählen',
    },
  },
  SERVICE_ADVERTISEMENT_MODAL: {
    HEADING: 'Ausschreibungsmails versenden',
    DESCRIPTION: [
      'Versenden Sie Ausschreibungsmails an Vertreter in bestimmten Bundesländern für den genannten Dienst.',
      'Vorsicht: Mit diesem Mailer erreichen Sie potentiell tausende Empfänger. Bitte mit äußerster Vorsicht nutzen!',
      'Eine Anrede und Grußformel werden automatisch in die E-Mail eingesetzt. Den Rest können Sie frei bearbeiten:',
    ],
    CLOSE: 'Schließen',
    FORM_LABELS: {
      STATE: 'Bundesland',
      ALL_STATES: 'An alle Bundesländer',
      SEND_TO_STATE: 'An Bundesländer schicken',
      SEND_TO_SPECIFIC: 'An einen Vertreter schicken',
      CHOOSE_SUBSTITUTE: 'Empfänger setzen',
      RECIPIENT: 'Empfänger: ',
      SUBJECT: 'Betreff',
      FORCE: 'Die Ausschreibungsmail auch Nutzern zustellen, die dieses Bundesland abgewählt haben.',
      MESSAGE: 'Ausschreibungsnachricht',
      WARNING_MESSAGE: 'Ausschreibungsmail versenden?',
      SUBMIT: 'Abschicken',
      SUBMIT_CONFIRM: 'Wirklich abschicken?',
      SUBMIT_SUCCESS: 'E-Mail versand. Sie können das Fenster jetzt schließen.',
      SUBMIT_ERROR: 'Es gab ein Problem beim E-Mail-Versand. Bitte versuchen Sie es später nocheinmal.',
    },
    ERRORS: {
      NO_SUBSTITUTE_SELECTED: 'Bitte einen Empfänger auswählen',
    },
  },
  FORM_LABELS: {
    FROM: 'Dienstbeginn',
    TO: 'Dienstende',
    CREATED_FROM: 'Erstellt Von',
    CREATED_TO: 'Erstellt Bis',
    TIER: 'Dringlichkeit',
    TYPE: 'Diensttyp',
    STATE: 'Bundesland',
    SEARCH: 'Suche',
    ASSIGN_OPTION: 'Zugewiesen',
    PUBLISHED: 'Veröffentlicht',
    CONCLUDED: 'Abgeschlossen',
    ORDER_NUMBER: 'Auftragsnummer',
    NEW_SERVICE: 'Neuen Dienst anlegen',
    SELECT_VALUES: {
      TYPES_LIST: [
        'Fahr- und Sitzdienste',
        'Fahrdienste',
        'Sitzdienste',
      ],
      ASSIGNED_LIST: [
        'Keine Auswahl',
        'Zugewiesene Dienste',
        'Nicht zugewiesene Dienste',
      ],
      PUBLISHED_LIST: [
        'Keine Auswahl',
        'Veröffentlichte Dienste',
        'Nicht veröffentlichte Dienste',
      ],
      CONCLUDED_LIST: [
        'Keine Auswahl',
        'Abgeschlossene Dienste',
        'Nicht abgeschlossene Dienste',
      ],
      TIER_LIST: [
        'Alle',
        'Standard Aufträge',
        'Eil Aufträge',
        'Express Aufträge',
      ],
      ALL_INCLUSIVE_LIST: [
        'Alle',
        'Nur Rundum-Sorglos Dienste',
      ],
      SERVICE_SUBMISSION_LIST: [
        'Neue Service Submissions',
        'Alle Service Submissions',
      ],
    },
  },
};
