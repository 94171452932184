export default {
  HEADING: 'KV-Dienste eintragen',
  LINK_BACK: 'Zurück',
  BTN: {
    ADD_SERVICE: 'Dienst hinzufügen',
    ADD_MORE_SERVICE: 'Weiteren Dienst hinzufügen',
    SUBMIT: 'KV-Dienste speichern und abschließen',
    INFORMATION: `Es gelten unsere <a href="${
      import.meta.env.ARZTPOOL_META_BASE_URL
    }conditions#customer" target="_blank">aktuellen Allgemeinen Geschäftsbedingungen</a>`,
  },
  TABLE: {
    HEADING: [
      'Betriebsstätte',
      'Arzt',
      'Dienststätte',
      'Beginn',
      'Ende',
      'Honorar',
    ],
  },
  ACTION: {
    DELETE: 'Löschen',
    EDIT: 'Bearbeiten',
  },
  DISCOUNT:
    'Ab dem {numberOfServices}. eingetragenen KV-Dienst, geben wir einen Rabatt von {discount}% auf die Vermittlungsgebühr aller eingetragenen KV-Dienste.',
  CALCULATION: [
    'Vermittlungsgebühr insg.',
    'statt',
  ],
};
