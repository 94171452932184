export default {
  HEADING: 'Vertretungsvertrag',
  TEXT: [
    'Zwischen',
    '- im folgenden Auftraggeber genannt -',
    'und',
    '- im folgenden Auftragnehmer genannt -',
    'wird folgender Vertrag geschlossen',
  ],
  PREAMBLE: {
    TITLE: 'Präambel',
    TEXT0:
      'Der Auftraggeber nimmt an der vertragsärztlichen Versorgung von gesetzlich krankenversicherten Patienten teil und ist damit verpflichtet, im Rahmen der Bereitschafts- oder Notdienstordnung seiner Kassenärztlichen Vereinigung Bereitschaftsdienste oder Notdienste (nachfolgend „KV-Dienste“) zu übernehmen. Der Auftragnehmer ist approbierter Arzt und selbständig, freiberuflich (vertragsärztlich oder rein privatärztlich) tätig. Die arztpool 365 GmbH (nachfolgend „arztpool“) vermittelt KV-Dienste über die von ihr angebotene Plattform.',
    TEXT1:
      'Mit diesem Vertrag soll die Vertretung eines einzelnen, über die Plattform der arztpool vermittelten, KV-Dienstes des Auftraggebers durch den Auftragnehmer geregelt werden.',
    TEXT2:
      'Nach dem Vertretungsvertrag soll kein Arbeitsverhältnis zwischen dem Vertragsarzt und dem Vertreter geschlossen werden, sondern die Vertretung soll in Form einer selbstständigen Tätigkeit erfolgen. Eine Beschäftigung im Sinn des § 7 Abs. 1 SGB IV und § 2 Nr. 9 SGB VI soll mit diesem Vertrag ausdrücklich nicht begründet werden.',
  },
  SUMMARY: {
    TITLE: 'Tätigkeitsnachweis für Auftrag Nr:',
    SUBSTITUTE_TITLE: 'Angeben zum Vertretungsarzt',
    SUBSTITUTE_NAME: 'Title / Name',
    SUBSTITUTE_IBAN: 'IBAN',
    SUBSTITUTE_BANK: 'BIC/Bank',
    SUBSTITUTE_TAX: 'Steuernummer',
    CUSTOMER_TITLE: 'Angeben zum Kunden',
    CUSTOMER_NAME: 'Arzt',
    CUSTOMER_ESTABLISHMENT: 'Praxis',
    CUSTOMER_ADDRESS: 'Addresse',
    CUSTOMER_SECONDARY_SERVICE_AREA: 'Dienstgebiet',
    SERVICE_TITLE: 'Angaben zum Dienst',
    DAY: 'Tag',
    DATE: 'Datum',
    START: 'Von',
    END: 'Bis',
    DURATION: 'Stunden',
    COMPLETE: 'Anzahl der Scheine',
    EXTRA: 'Extravergutung ab dem',
    EXTRA_LEFT_TITLE: 'Bitte Anzahl eintragen:',
    EXTRA_LEFT_1: 'Totenschein(e)',
    EXTRA_LEFT_2: 'Privatpatient(en)',
    EXTRA_LEFT_3: 'Telefonische Beratung(en). Bitte bei telefon. Beratungen Notfallschein ausfullen!',
    EXTRA_LEFT_4: 'Totenscheine. Privatpatienten und telefonische Beratungen gehoren nicht zur Anzhal der Scheine.',
    NOTICE: 'Hinweis:',
    EXTRA_RIGHT_TITLE: 'Bitte ankreuzen:',
    PHONE: 'Telefonnummber:',
    DATE_LOCATION: 'Ort, Datum',
    STAMP: 'Unterschrift / Stempel vertretender Arzt',
    ORDER_NUMBER: 'Auftrag:',
  },
  PARAGRAPHS: [
    {
      TITLE: '1. Leistungspflichten des Auftragnehmer',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, für den Auftrageber folgenden KV-Dienste in Vertretung zu übernehmen: Auftragsnummer { orderNumber }',
          SERVICE_ITEM_TEXT: 'am { start } Uhr bis { end } Uhr',
        },
        {
          TEXT: 'Die vom Auftragnehmer für den Auftraggeber zu erbringenden Dienstleistungen umfassen dabei alle im Rahmen des KV-Dienstes anfallenden ärztlichen und verwaltungstechnischen Aufgaben, insbesondere die eigenständige und eigenverantwortliche ärztliche Versorgung der den KV-Dienst in Anspruch nehmenden Patienten.',
        },
      ],
    },
    {
      TITLE: '2. Vertragsdurchführung',
      LISTING: [
        {
          TEXT: 'Die Durchführung der Vertretung richtet sich nach den jeweilig anwendbaren Verordnungen über die Durchführung der ärztlichen Bereitschaft- und Notdienste (Notfalldienstordnung, Notdienstordnung, Bereitschaftsdienstordnung, etc.), dem weiteren anwendbaren Satzungsrecht der jeweiligen Kassenärztlichen Vereinigung und den gesetzlichen Bestimmungen (nachfolgend zusammen „rechtliche Bestimmungen“). Die Parteien verpflichten sich, die vorgenannten Regelungen einzuhalten.',
        },
        {
          TEXT: 'Der Auftragnehmer ist bei der Durchführung der KV-Dienste in seiner Berufsausübung frei und unterliegt keinem Weisungsrecht des Auftraggebers. Er führt den KV-Dienst in eigener Verantwortung nach bestem Wissen und Gewissen, sowie aufgrund seiner fachlichen Qualifikation selbstständig und nach den Regeln der ärztlichen Kunst aus. Ein Weisungsrecht hinsichtlich Zeit, Dauer, Ort und Art der Tätigkeit besteht ebenfalls nicht. Zeitlicher und örtlicher Umfang ergeben sich ausschließlich aus der vertraglichen Vereinbarung gemäß Ziffer 1.1.',
        },
        {
          TEXT: 'Zur Ausübung weiterer ärztlicher und nichtärztlicher Tätigkeiten neben der Vertretungstätigkeit ist der Auftragnehmer uneingeschränkt berechtigt.',
        },
        {
          TEXT: 'Nach dem Willen der Parteien soll kein Arbeitsverhältnis begründet werden, sondern die Vertretung erfolgt auf der Grundlage einer selbständigen Tätigkeit des Auftragnehmers.',
        },
        {
          TEXT: 'Der Auftragnehmer erbringt die Dienstleistungen grundsätzlich mit von ihm zu stellenden Hilfsmitteln, Werkzeugen und Materialien, wobei der Auftraggeber dem Auftragnehmer diese unentgeltlich überlässt, soweit der Auftragnehmer für die Durchführung des KV-Dienstes aufgrund von vertragsärztlichen Regelungen auf Hilfsmittel, Werkzeuge und Materialien des Auftraggebers angewiesen ist (z.B. Rezepte, Verordnungen, Pflicht zum Abhalten eines KV-Dienstes in den Räumlichkeiten des Auftragnehmers etc.).',
        },
      ],
    },
    {
      TITLE: '3. Verhinderung des Auftragnehmers',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer ist verpflichtet, im Falle der Unmöglichkeit der Leistungserbringung, gleich aus welchem Grund, den Auftraggeber und arztpool unverzüglich zu informieren. Der Auftragnehmer haftet dem Auftraggeber für etwaige Kosten, die dem Auftraggeber durch den Ausfall entstehen, insoweit er die Verhinderung zu vertreten hat.',
        },
      ],
    },
    {
      TITLE: '4. Nachweis für ärztliche Tätigkeit',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer stellt dem Auftraggeber, vor Antritt der Vertretung, die im Folgenden genannten Unterlagen zur Verfügung:',
          SUB_LISTING: [
            'Kopie des Personalausweises',
            'Kopie der Approbationsurkunde oder des Nachweises nach § 10 BÄO über die Erlaubnis zur Ausübung des ärztlichen Berufs',
            'Soweit für die Vertretung erforderlich weitere Fortbildungszertifikate, (Qualifizierungsnachweise über Facharztausbildung, Ausbildung zum Notarzt etc.)',
          ],
        },
        {
          TEXT: 'Der Nachweis gegenüber dem Auftraggeber gilt als erbracht, sobald der Auftragnehmer diese Unterlagen der arztpool übermittelt. Der Auftraggeber ist jederzeit berechtigt, die Herausgabe dieser Unterlagen von arztpool zu verlangen; hierzu stimmt der Auftragnehmer schon jetzt ausdrücklich und unwiderruflich zu.',
        },
        {
          TEXT: 'Auf Verlangen des Auftraggebers hat der Auftragnehmer ein polizeiliches Führungszeugnis vorzulegen. Ziffer 4 Abs. 2 gilt entsprechend.',
        },
      ],
    },
    {
      TITLE: '5. Nachweis über Berufshaftpflichtversicherung',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, zur Abdeckung der Haftung für die vertraglich vereinbarte Vertretungstätigkeit des KV-Dienstes eine Berufshaftpflichtversicherung nach § 95e SGB V zu unterhalten und deren Bestehen und deren Umfang dem Vertragsarzt vor Antritt der Vertretung nachzuweisen. Ziffer 4.2 gilt entsprechend.',
        },
      ],
    },
    {
      TITLE: '6. Prüfverfahren Sozialversicherungspflicht',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, dem Auftraggeber im Fall der Durchführung eines Prüfverfahrens durch die Träger der Kranken- und Rentenversicherung die notwendigen Unterlagen und Belege zur Verfügung zu stellen, die über seine sonstigen Beschäftigungsverhältnisse entsprechend den Anforderungen des Prüfverfahrens Auskunft geben.',
        },
        {
          TEXT: 'Der Auftraggeber kann die Vorlage geeigneter Nachweise verlangen, ob und in welchem Umfang eine private Rentenversicherung zur Absicherung bei Alter und Invalidität und eine Krankenversicherung bestehen sowie sich schriftlich versichern lassen, dass eine ausreichende soziale Absicherung besteht. Als ausreichende Krankenversicherung gilt auch die freiwillige Versicherung in einer gesetzlichen Krankenversicherung.',
        },
      ],
    },
    {
      TITLE: '7. Vergütung',
      LISTING: [
        {
          TEXT: 'Der Auftraggeber zahlt an den Auftragnehmer eine Vergütung von { pricePerHour } € pro beauftragter und tatsächlich erbrachter Stunde KV-Dienst. Sollte aufgrund rechtlicher Bestimmungen Mehrarbeit erforderlich sein, wird diese anteilig vergütet. Die anteilige Vergütung erfolgt pro angefangener Viertelstunde. An Feiertagen beträgt die Vergütung { pricePerHour } €. Als Feiertage gelten an Ostern Karfreitag/ Samstag/Sonntag/Montag, an Pfingsten Samstag/Sonntag/Montag, an Weihnachten Heilig Abend/ erster und zweiter Weihnachtsfeiertag sowie Silvester und Neujahr (ab 00:00 Uhr des betreffenden Tages bis 24:00 Uhr). Die Vergütung ist gemäß § 4 Nr. 14 UStG umsatzsteuerfrei.',
        },
        {
          TEXT: 'Hinzu kommt eine leistungsabhängige Vergütung. Der Auftragnehmer erhält { bonusFee } € Extravergütung für jeden weiteren Patienten wie folgt,',
          SUB_LISTING: [
            {
              TEXT: 'ab dem 3. Patienten bei einem Dienst bis 11 Stunden',
            },
            {
              TEXT: 'ab dem 5. Patienten bei einem Dienst von 12 Stunden',
            },
            {
              TEXT: 'ab dem 7. Patienten bei einem Dienst von 17 Stunden',
            },
            {
              TEXT: 'ab dem 9. Patienten bei einem Dienst von 24 Stunden',
            },
          ],
        },
        {
          TEXT: 'Im Falle der Behandlung von Patienten, die nicht gesetzlich versichert sind, steht dem Auftragnehmer neben der Vergütung gemäß vorstehenden Regelungen das Recht zur Privatliquidation zu. Der Auftragnehmer schließt sämtliche diesbezüglichen Vereinbarungen mit den Patienten in eigenem Namen und führt alle organisatorischen Maßnahmen in diesem Zusammenhang in Eigenregie sowie auf eigene Kosten und Verantwortung aus.',
        },
        {
          TEXT: 'Mit dieser Vergütung sind die Tätigkeit und der Aufwand des Auftragnehmers vollumfänglich abgegolten. Ein Anspruch auf gesonderten Aufwendungsersatz besteht nicht.',
        },
        {
          TEXT: 'Bei Verhinderung des Auftragnehmers wird der Auftraggeber von seiner Vergütungspflicht gegenüber dem Vertreter befreit.',
        },
        {
          TEXT: 'Der Auftragnehmer übermittelt an den Auftraggeber innerhalb von 10 Tagen nach Durchführung des KV-Dienstes eine Rechnung über das Stunden- sowie angefallene Extravergütung mit einem entsprechenden Tätigkeitsnachweis. Die Vergütung ist vom Auftraggeber innerhalb von 14 Tagen nach Zugang einer ordnungsgemäßen Rechnung ohne Abzug zu begleichen. Der Auftragnehmer beauftragt arztpool mit der Rechnungserstellung und stellt hierzu alle notwendigen Unterlagen und Nachweise.',
        },
        {
          TEXT: 'Hinzu kommt eine leistungsabhängige Vergütung, insoweit eine solche vom Auftraggeber angeboten wird.',
        },
      ],
    },
    {
      TITLE: '8. Stornierung des Vertrages durch den Auftraggeber',
      // Not used
      // LISTING: [
      //   {
      //     TEXT: 'Für den in §1, Abs. 1 definierten Dienst erhält der Auftragnehmer vom Auftragnehmer ein Honorar in Höhe von { totalSum }, das entspricht { pricePerHour }€ pro Stunde.',
      //   },
      //   {
      //     TEXT: 'Die Extrapauschale wird in Abhängigkeit des Patientenaufkommens, nur bei Hausbesuchsdiensten/Fahrdiensten berechnet, auf Grundlage der aktuellen Preisliste von arztpool 365 GmbH.',
      //   },
      //   {
      //     TEXT: 'Führt der Auftragnehmer den KV-Dienst nicht durch, gleich aus welchem Grund, wird der Auftraggeber von seiner Vergütungspflicht befreit.',
      //   },
      //   {
      //     TEXT: 'Der Auftragnehmer erstellt eine entsprechende Rechnung über das Stunden- sowie angefallene Extrahonorar nach Durchführung des KV-Dienstes mit einem entsprechenden Tätigkeitsnachweis. Das Honorar ist vom Auftraggeber innerhalb von 14 Tagen ohne Abzug zu begleichen.',
      //   },
      // ],
    },
    {
      TITLE: '8. Haftung',
      LISTING: [
        {
          TEXT: 'Der Auftragnehmer haftet für Pflichtverletzungen gegenüber Dritten im Rahmen des KV-Dienstes selbst, soweit sich aus den rechtlichen Bestimmungen nichts anderes ergibt.',
        },
        {
          TEXT: 'Der Auftraggeber verpflichtet sich im Fall seiner oder der Inanspruchnahme des Auftragnehmers aus vertraglichen oder deliktischen Ansprüchen Dritter den Auftragnehmer sofort darüber in Kenntnis zu setzen und diesem ohne schuldhaftes Zögern alle notwendigen Unterlagen, insbesondere die vollständige Patientendokumentation des jeweiligen Dritten, zur Abwehr des Anspruches zur Verfügung zu stellen, auch wenn der Vertrag bereits beendet wurde.',
        },
        {
          TEXT: 'Der Auftragnehmer verpflichtet sich, dem Auftraggeber Mitteilung zu machen, sobald ihm haftungsrelevante Tatsachen im Rahmen seiner vertraglichen Tätigkeit bekannt werden.',
        },
        {
          TEXT: 'Im Übrigen richtet sich die Haftung nach den gesetzlichen Bestimmungen.',
        },
      ],
    },
    {
      TITLE: '9. Geheimhaltung',
      LISTING: [
        {
          TEXT: 'Die Parteien verpflichten sich über die Regelung dieses Vertrages auch über das Vertragsende hinaus Stillschweigen zu bewahren.',
        },
        {
          TEXT: 'Der Auftragnehmer ist verpflichtet über alle vertraulichen kaufmännischen, technischen, organisatorischen, wirtschaftlichen und patientenbezogenen Informationen (ärztliche Schweigepflicht), die ihm im Zusammenhang mit seiner Tätigkeit für den Auftraggeber bekannt werden, Stillschweigen auch über das Vertragsende hinaus zu bewahren. Er ist befugt, die ihm anvertrauten personenbezogenen Daten im Rahmen der Zweckbestimmung des Auftraggebers zu verarbeiten. Die Verschwiegenheitspflicht gilt gegenüber jedermann, es sei denn, der Auftragnehmer ist gesetzlich zur Auskunft verpflichtet oder die Auskunft ist Behörden gegenüber geboten oder zur Wahrung von Rechtsansprüchen gegenüber Gerichten erforderlich.',
        },
        {
          TEXT: 'Die Verschwiegenheitsverpflichtung nach dieser Ziffer gilt nicht gegenüber arztpool, soweit diese Informationen für die Vertragsabwicklung zwischen dem Auftragnehmer und Auftraggeber oder für die ordnungsgemäße Erfüllung des zwischen dem Auftraggeber und arztpool oder des zwischen Auftragnehmer und arztpool bestehenden Vertragsverhältnisses benötigt wird.',
        },
      ],
    },
    {
      TITLE: '10. Vertragsdauer',
      LISTING: [
        {
          TEXT: 'Dieser Vertrag tritt mit seiner (digitalen) Unterzeichnung oder der Annahme durch beide Parteien auf der Vermittlungsplattform von arztpool in Kraft und endet automatisch mit dem Ende des KV-Dienstes.',
        },
      ],
    },
    {
      TITLE: '11. Schlussbestimmungen',
      LISTING: [
        {
          TEXT: 'Mündliche Nebenabreden wurden nicht getroffen. Änderungen und Ergänzungen dieses Vertrages bedürfen der Schriftform. Dies gilt auch für eine Abänderung dieser Schriftformklausel.',
        },
        {
          TEXT: 'Sollte eine Bestimmung dieses Vertrages unwirksam sein oder werden, wird die Wirksamkeit der übrigen Bestimmungen davon nicht berührt.',
        },
      ],
    },
  ],
};
