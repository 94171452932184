export default {
  CLOSE: 'Schließen',
  HEADER: 'Information zu Sofort-Zusage',
  SUB_HEADER: 'Sie haben sich für die Sofort-Zusage entschieden, um sich diesen KV-Dienst direkt und ohne Wartezeit zu sichern.',
  CARD: {
    HEADER: 'Sofort-Zusage',
    TEXT: 'Die Sofort-Zusage ermöglicht Ihnen, sich Ihren Dienst direkt und ohne Wartezeit zu sichern. Sie akzeptieren damit das Mindesthonorar von {minFee}€/Std. für diesen KV-Dienst. Mit Vermittlung dieses Dienstes fällt eine Vermittlungsgebühr von {price}',
  },
  CONFIRM: `
      Ich akzeptiere die <a href="${import.meta.env.ARZTPOOL_META_BASE_URL}conditions#substitute" target="_blank">AGB</a> sowie den <a href="${
    import.meta.env.ARZTPOOL_META_BASE_URL
  }sampleContract" target="_blank">Vertretungsvertrag</a>
      `,
  BUTTON: {
    CANCEL: 'Abbrechen',
    SUBMIT: 'Bestätigen',
  },
};
