// global definitions of event keys, used in EventBus service

enum Events {
  REFRESH_USER = 'refresh_user_event',
  MESSAGES_OPEN = 'messages_open_event',
  LOGIN_OPEN = 'login_open_event',
  LOGIN_ERROR = 'login_error_event',
  CONTRACT_OPEN = 'contract_modal_open',
  INVOICE_OPEN = 'invoice_modal_open',
  SUBSTITUTION_NOTICE_OPEN = 'exchange_request_open',
  CREATE_CONTRACT_PDF = 'create_contract_pdf',
  CREATE_NOTICE_PDF = 'create_notice_pdf',
  CREATE_INVOICE_PDF = 'create_invoice_pdf',
  SETUP_OPEN = 'setup_open_event',
  SETUP_ERROR = 'setup_error_event',
  TRIGGER_SETUP = 'trigger_setup_event',
  UPDATE_SETUP_STEP = 'update_setup_step_event',
  PASSWORD_RESET_OPEN = 'password_reset_open_event',
  PASSWORD_RESET_ERROR = 'password_reset_error_event',
  NEW_PASSWORD_ERROR = 'new_password_error_event',
  ACCOUNT_CONFIRMATION_ERROR = 'account_confirmation_error_event',
  ACCOUNT_RESEND_CONFIRMATION_ERROR = 'account_resend_confirmation_error_event',
  HOME_INTRODUCTION_SCROLL = 'home_scroll_to_introduction_event',
  REGISTER_OPEN = 'register_open_event',
  PREREGISTER_OPEN = 'preregister_open_event',
  REGISTER_ERROR = 'register_error_event',
  SERVICE_DETAIL_CONFIRMATION_OPEN = 'service_detail_confirmation_open_event',
  SERVICE_DETAIL_CONFIRMATION_ERROR = 'service_detail_confirmation_error_event',
  SERVICE_FORM_OPEN = 'service_form_open_event',
  INSTANT_APPLY_CONFIRM_OPEN = 'instant_apply_cornfirm_open_event',
  PRIORITY_CONFRIM_OPEN = 'priority_cornfirm_open_event',
  PARTNER_LOAD_MOBILE_SERVICES_COMPLETE = 'partner_mobile_services_load_complete',
  PARTNER_LOAD_OFFICE_SERVICES_COMPLETE = 'partner_office_services_load_complete',
  PARTNER_LOAD_INSTITUTIONS_COMPLETE = 'partner_institutions_load_complete',
  PARTNER_SINGLE_SERVICE_FEE_CONFIRM_OPEN = 'partner_single_service_fee_confirm_open_event',
  PARTNER_SINGLE_SERVICE_FEE_OPEN = 'partner_single_service_fee_open_event',
  SUBSTITUTE_LOAD_ASSIGNMENTS_COMPLETE = 'substitute_assignments_load_complete',
  SUBSTITUTE_LOAD_APPLICATIONS_COMPLETE = 'substitute_applications_load_complete',
  SUBSTITUTE_LOAD_BOOKMARKS_COMPLETE = 'substitute_bookmarks_load_complete',
  SUBSTITUTE_LOAD_INSURANCES_COMPLETE = 'substitute_insurances_load_complete',
  SUBSTITUTE_LOAD_COMPLETE = 'substitute_load_complete',
  ESTABLISHMENT_DOCTOR_MODAL_OPEN = 'establishment_doctor_modal_open_event',
  ESTABLISHMENT_DOCTOR_UPDATED = 'establishment_doctor_updated',
  ESTABLISHMENT_SERVICEAREA_MODAL_OPEN = 'establishment_service_area_modal_open_event',
  OPERATORS_ADD_TAB = 'operators_add_tab_event',
  OPERATORS_REMOVE_TAB = 'operators_remove_tab_event',
  OPERATORS_SERVICE_AREA_MODAL_OPEN = 'operators_service_area_modal_open',
  OPERATORS_SERVICE_AREA_MODAL_CLOSE = 'operators_service_area_modal_close',
  OPERATORS_SERVICE_MODAL_OPEN = 'operators_service_modal_open',
  OPERATORS_SERVICE_MODAL_CLOSE = 'operators_service_modal_close',
  OPERATORS_CONTRACT_MODAL_OPEN = 'operators_contract_modal_open',
  OPERATORS_VANILLA_CONTRACT_MODAL_OPEN = 'operators_vanilla_contract_modal_open',
  OPERATORS_INVOICE_MODAL_OPEN = 'operators_invoice_modal_open',
  OPERATORS_SUBNOTICE_MODAL_OPEN = 'operators_subnotice_modal_open',
  OPERATORS_SEND_DOCUMENTS_MODAL_OPEN = 'operators_send_documents_modal_open',
  OPERATORS_SEND_DOCUMENTS_MODAL_SENT = 'operators_send_documents_modal_sent',
  OPERATORS_ESTABLISHMENT_SERVICE_AREA_MODAL_OPEN = 'operators_establishment_service_area_modal_open',
  OPERATORS_ESTABLISHMENT_SERVICE_AREA_UPDATED = 'operators_establishment_service_area_updated',
  OPERATORS_ESTABLISHMENT_SERVICE_AREA_MODAL_CLOSE = 'operators_establishment_service_area_modal_close',
  OPERATORS_SERVICE_SUMMARY_MODAL_OPEN = 'operators_service_summary_modal_open',
  OPERATORS_SERVICE_BILLING_SUMMARY_MODAL_OPEN = 'operators_service_billing_summary_modal_open',
  OPERATORS_SERVICE_UPDATED = 'operators_service_updated',
  OPERATORS_USER_UPDATED = 'operators_user_updated',
  OPERATORS_SERVICEAREA_UPDATED = 'operators_servicearea_updated',
  OPERATORS_INSURANCE_UPDATED = 'operators_insurance_updated',
  OPERATORS_SERVICE_ADVERTISEMENT_MAILING_MODAL_OPEN = 'operators_service_advertisement_mailing_modal_open',
  OPERATORS_SERVICE_ADVERTISEMENT_MAILING_MODAL_CLOSE = 'operators_service_advertisement_mailing_modal_close',
  OPERATORS_PARTNERS_MASS_MAIL_MODAL_OPEN = 'operators_partners_mass_mail_modal_open',
  OPERATORS_PARTNERS_MASS_MAIL_MODAL_CLOSE = 'operators_partners_mass_mail_modal_close',
  OPERATORS_OPEN_ORDER_CONFIRMATION = 'operators_open_order_confirmation',
  OPERATORS_CLOSE_ORDER_CONFIRMATION = 'operators_close_order_confirmation',
  OPERATORS_ORDER_CONFIRMATION_SENT = 'operators_order_confirmation_sent',
  OPERATORS_ORDER_SUMMARY_SENT = 'operators_order_summary_sent',
  OPERATORS_OPEN_ORDER_SUMMARY = 'operators_open_order_summary',
  OPERATORS_CLOSE_ORDER_SUMMARY = 'operators_close_order_summary',
  OPERATORS_SHOW_EXCEPTION = 'operators_show_exception',
  OPERATORS_DEACTIVATE_USER = 'operators_deactivate_user',
  OPERATORS_NEW_USER_MODAL_OPEN = 'operators_new_user_modal_open',
  OPERATORS_NEW_USER_MODAL_CLOSE = 'operators_new_user_modal_close',
}

export default Events;
